import data from "./statementData.js"
import htmlToPdf from "../../../assets/docxtemplater/htmlToPdf";
import GitChartImg from "../../../assets/docxtemplater/GitChartImg";
import '../../../assets/docxtemplater/pizzip'
import '../../../assets/docxtemplater/docxtemplater'
import '../../../assets/docxtemplater/FileSaver'
import '../../../assets/docxtemplater/pizzip-utils'

export default {
  name: "statement",
  data,
  created() {
  },
  computed: {},
  methods: {
    exportToPDF() {
      //判断iframe是否存在，不存在则创建iframe
      let iframe = document.getElementById("print-iframe");
      let el = document.getElementById("statement");
      let doc = null;

      if (!iframe) {
        iframe = document.createElement('IFRAME');
      }

      iframe.setAttribute("id", "print-iframe");
      iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:-500px;top:-500px;');
      document.body.appendChild(iframe);
      doc = iframe.contentWindow.document;
      //这里可以自定义样式
      doc.write('<link rel="stylesheet" type="text/css" href="/static/statement.css">');
      doc.write('<div>' + el.innerHTML + '</div>');
      doc.close();
      iframe.contentWindow.focus();

      iframe.contentWindow.print();
      if (navigator.userAgent.indexOf("MSIE") > 0) {
        document.body.removeChild(iframe);
      }
    },

    base64DataURLToArrayBuffer(dataURL) {
      const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
      if (!base64Regex.test(dataURL)) {
        return false;
      }
      const stringBase64 = dataURL.replace(base64Regex, "");
      let binaryString;
      if (typeof window !== "undefined") {
        binaryString = window.atob(stringBase64);
      } else {
        binaryString = new Buffer(stringBase64, "base64").toString("binary");
      }
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return bytes.buffer;
    },
    // 点击导出word
    exportWord: function () {
      const ImageModule = require('docxtemplater-image-module-free');

      let _this = this;
      let word = window.location.origin;
      if (this.form.cover) {
        word += "/static/statement1.docx";
      } else {
        word += "/static/statement.docx";
      }
      // 读取并获得模板文件的二进制内容
      PizZipUtils.getBinaryContent(word, function (error, content) {
        // 抛出异常
        if (error) {
          throw error;
        }
        // 图片处理
        let opts = {};
        opts.centered = true;  // 图片居中，在word模板中定义方式为{%%image}
        opts.fileType = "docx";
        opts.getImage = function (tagValue) {
          return _this.base64DataURLToArrayBuffer(tagValue);
        };
        opts.getSize = function (img, tagValue, tagName) {
          if (tagName === 'background') {
            return [793, 1120]
          } else if (tagName === 'cma') {
            return [233, 198]
          } else if (tagName === 's1') {
            return [100, 41]
          } else if (tagName === 's2') {
            return [100, 41]
          } else if (tagName === 's3') {
            return [100, 41]
          } else {
            return [400, 400]
          }
        };
        let imageModule = new ImageModule(opts);

        // 创建一个PizZip实例，内容为模板的内容
        let zip = new PizZip(content);
        // 创建并加载docxtemplater实例对象
        let doc = new window.docxtemplater();
        doc.loadZip(zip);
        doc.attachModule(imageModule);


        // 设置模板变量的值
        doc.setData({
          ..._this.form,
          table: _this.tableData,
        });

        try {
          // 用模板变量的值替换所有模板变量
          doc.render();
        } catch (error) {
          // 抛出异常
          let e = {
            message: error.message,
            name: error.name,
            stack: error.stack,
            properties: error.properties
          };
          // // console.log(JSON.stringify({error: e}));
          throw error;
        }

        // 生成一个代表docxtemplater对象的zip文件（不是一个真实的文件，而是在内存中的表示）
        let out = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        });
        // 将目标文件对象保存为目标类型的文件，并命名
        saveAs(out, _this.form.title + ".docx");
      });
    },
    //显示报表
    showLayer() {
      let {tableData, conclusion, overproofLen, form} = this.$parent.disposeChartData();
      this.layerForm = form;
      this.tableData = tableData;
      this.form.overproof = overproofLen;
      this.form.conclusion = conclusion;
      for (let i in form) {
        this.form[i] = form[i] ? form[i] : '-'
      }
      this.form.cover = form.cover;
      this.percent = 0;


      this.setWordImages(this.tableData, 0, this.tableData.length);
      // this.getBase64();
    },
    //隐藏弹窗
    closeLayer() {
      $('.statementPage').fadeOut(300)
    },
    //绘制折线图
    setWordImages(data, index, length) {
      let beforePro = this.$store.state.progress;
      new GitChartImg(data[index].data, res => {
        this.tableData[index].image = res;
        if (this.interval) {
          clearInterval(this.interval);
        }
        let percent = parseInt(index / this.tableData.length * 100) * ((100 - beforePro) / 100);
        let sum = parseInt(percent) + beforePro;
        this.myProgressBar.set(sum);
        if (length === index + 1) {
          this.myProgressBar.set(100)
          if (this.$store.state.createReport) {
            $('.statementPage').fadeIn(300, e => {
              this.myProgressBar.hide();
            });
          } else {
            this.myProgressBar.hide();
            this.$messages('用户取消生成报表！', 'warning')
          }
        } else {
          ++index;
          if (this.$store.state.createReport) {
            this.setWordImages(data, index, length)
          } else {
            this.myProgressBar.set(100);
            this.myProgressBar.hide();
            this.$messages('用户取消生成报表！', 'warning')
          }
        }
      }, {merge: true, width: 800, height: 266});
    },
    //返回
    goBack() {
      $('.statementPage').fadeOut(300, e => {
        $('.layerContainerBox').fadeIn(300)
      })
    },
    //发送数据后端执行生成并保存
    saveWord() {
      let url = '';
      let {title, project, regulation, remarks, device, CVal} = this.layerForm;
      let session = this.$session.get();
      let checkedDevice = session.bPSchedule.data;
      let pageType = session.bPSchedule.pageType;
      let data = {
        title: title ? title.split('（')[0] : '-',
        project: project ? project : '-',
        regulation: regulation ? regulation : '-',
        remarks: remarks ? remarks : '-',
        rfs: [],
        device: device,
      };

      if (typeof CVal === "number" || typeof CVal === "string") {
        data.rfs.push(CVal)
      } else {
        for (let item of CVal) {
          data.rfs.push(item.min);
          data.rfs.push(item.max);
        }
      }
      data.rfs = data.rfs.toString();

      if (pageType === 'sta') {
        data.fileIdList = checkedDevice.toString();
        url = `/data/bpzd/bpsreport`;
      } else {
        url = `/data/bpzd/bpsreport2`;
        let {fileId, fileIdList, pointIdList} = checkedDevice;
        data.fileId = fileId.toString();
        data.fileIdList = fileIdList.toString();
        data.pointIdList = pointIdList.toString();
      }

      this.axios.get(url, {params: data}).then(res => {
        if (res.status) {
          this.$messages(res.message, 'success');
        } else {
          this.$messages(res.message, 'error');
        }
      });
    },
    //把图片转为base64格式
    imageToBase64(src) {
      let suffix = src.split('.')[1];
      let image = new Image();
      image.crossOrigin = '*';
      image.src = src;
      return new Promise(resolve => {
        image.onload = function () {
          let canvas = document.createElement('canvas');
          let ctx = canvas.getContext('2d');
          canvas.width = image.width;
          canvas.height = image.height;
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
          let base64 = canvas.toDataURL('image/' + suffix);
          resolve(base64);
        }
      })
    },
    //设置图片
    async getBase64() {
      this.form.background = await this.imageToBase64('/static/images/2222.jpg');
      this.form.cma = await this.imageToBase64('/static/images/1112.png');
      this.form.s1 = await this.imageToBase64('/static/images/4444.png');
      this.form.s2 = await this.imageToBase64('/static/images/5555.png');
      this.form.s3 = await this.imageToBase64('/static/images/6666.png');
      await this.setImages(this.form.background, this.form.cma, this.form.s1, this.form.s2, this.form.s3);
    },
    setImages(bgSrc, cmaSrc, src1, src2, src3) {
      let background = `<img style="width: 100%;height: 100%;float: left;overflow: hidden;" src="${bgSrc}" alt="背景">`;
      let cma = `<img style="width: 100%;height: 100%;float: left;overflow: hidden;" src="${cmaSrc}" alt="cma">`;
      let s1 = `<img style="width: 100%;height: 100%;float: left;overflow: hidden;border-bottom: 1px solid #333;" src="${src1}" alt="cma">`;
      let s2 = `<img style="width: 100%;height: 100%;float: left;overflow: hidden;border-bottom: 1px solid #333;" src="${src2}" alt="cma">`;
      let s3 = `<img style="width: 100%;height: 100%;float: left;overflow: hidden;border-bottom: 1px solid #333;" src="${src3}" alt="cma">`;
      $('#background').empty().append(background);
      $('#cma').empty().append(cma);
      $('#signature1').empty().append(s1);
      $('#signature2').empty().append(s2);
      $('#signature3').empty().append(s3);
    }
  },
  mounted() {
  },
  beforeDestroy() {
  },
  watch: {}
}
