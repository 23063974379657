<template>
	<div class="historyExpPage pageContainer">
		<!-- <div class="pageMenu">
			<ul class="nav no-select-text">
				<li @click="switchNavMenu('HistorySta')">测点数据</li>
				<li class="selected">关联数据</li>
			</ul>

			<div class="screen">
				<span>测点：</span>
				<el-select v-model="selectStation" @change="selectData" placeholder="请选择筛选条件">
					<el-option v-for="item in stationType" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</div>
		</div> -->

		<div class="tableHeader no-select-text">
			<span style="width: 5%;"><el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="selectAllDevice">全选</el-checkbox></span>
			<span style="width: 5%;">序号</span>
			<span style="width: 10%;" @click="selectRankData('number')" :class="item.rank === 'number' ? 'pointer select' : 'pointer'">
				测点名称
				<i :class="item.type_ ? 'el-icon-bottom' : 'el-icon-top'"></i>
			</span>
			<span style="width: 10%;">名称/位置</span>
			<span style="width: 12%;">文件名</span>
			<span style="width: 12%;">
				<em @click="selectRankData('x')" :class="item.rank === 'x' ? 'pointer select' : 'pointer'">
					幅值（X）
					<i :class="item.type_ ? 'el-icon-bottom' : 'el-icon-top'"></i>
				</em>
				<em>主频（X）</em>
			</span>
			<span style="width: 12%;">
				<em @click="selectRankData('y')" :class="item.rank === 'y' ? 'pointer select' : 'pointer'">
					幅值（Y）
					<i :class="item.type_ ? 'el-icon-bottom' : 'el-icon-top'"></i>
				</em>
				<em>主频（Y）</em>
			</span>
			<span style="width: 12%;">
				<em @click="selectRankData('z')" :class="item.rank === 'z' ? 'pointer select' : 'pointer'">
					幅值（Z）
					<i :class="item.type_ ? 'el-icon-bottom' : 'el-icon-top'"></i>
				</em>
				<em>主频（Z）</em>
			</span>
			<span style="width: 6%;">预警值</span>
			<span style="width: 6%;text-align: center;">预警报警</span>
			<span style="width: 10%;text-align: center;text-indent: 0">编辑</span>
		</div>

		<div class="tableContainer">
			<div class="tableContent">
				<el-scrollbar :native="false" :noresize="false" tag="div" v-if="listData.length != 0">
					<ul :class="getClazz(p)" v-for="(p, i) in listData" :key="i">
						<el-checkbox-group v-model="checkedArr" @change="selectDevice($event, p)">
							<li class="no-select-text" style="width: 5%;"><el-checkbox :label="p.index"></el-checkbox></li>
							<li style="width: 5%;">{{ i+1 }}</li>
							<li style="width: 10%;" :title="p.pointName">{{ p.pointName }}</li>
							<li style="width: 10%;" :title="p.address">{{ p.address ? p.address : '-' }}</li>
							<li style="width: 12%;">
								<span v-show="p.id == 0">-</span>
								<span class="blue" v-show="p.id != 0" :title="timeHandle(p.fileTime,p.suffix)">{{ timeHandle(p.fileTime,p.suffix) }}</span>
							</li>
							<li style="width: 12%;">
								<em v-show="p.id == 0">-</em>
								<em v-show="p.id == 0">-</em>
								<em v-show="p.id != 0" :title="p.xmax !== '-' ? toFixed(p.xmax, 4) + p.unit : '-'">
									{{ p.xmax !== '-' ? toFixed(p.xmax, 4) : '-' }}{{ p.xmax !== '-' ? p.unit : '' }}
								</em>
								<em v-show="p.id != 0" :title="p.xfqc !== '-' ? toFixed(p.xfqc, 1) + 'Hz' : '-'">{{ p.xfqc !== '-' ? toFixed(p.xfqc, 1) + 'Hz' : '-' }}</em>
							</li>
							<li style="width: 12%;">
								<em v-show="p.id == 0">-</em>
								<em v-show="p.id == 0">-</em>
								<em v-show="p.id != 0" :title="p.ymax !== '-' ? toFixed(p.ymax, 4) + p.unit : '-'">
									{{ p.ymax !== '-' ? toFixed(p.ymax, 4) : '-' }}{{ p.ymax !== '-' ? p.unit : '' }}
								</em>
								<em v-show="p.id != 0" :title="p.yfqc !== '-' ? toFixed(p.yfqc, 1) + 'Hz' : '-'">{{ p.yfqc !== '-' ? toFixed(p.yfqc, 1) + 'Hz' : '-' }}</em>
							</li>
							<li style="width: 12%;">
								<em v-show="p.id == 0">-</em>
								<em v-show="p.id == 0">-</em>
								<em v-show="p.id != 0" :title="p.zmax !== '-' ? toFixed(p.zmax, 4) + p.unit : '-'">
									{{ p.zmax !== '-' ? toFixed(p.zmax, 4) : '-' }}{{ p.zmax !== '-' ? p.unit : '' }}
								</em>
								<em v-show="p.id != 0" :title="p.zfqc !== '-' ? toFixed(p.zfqc, 1) + 'Hz' : '-'">{{ p.zfqc !== '-' ? toFixed(p.zfqc, 1) + 'Hz' : '-' }}</em>
							</li>
							<li style="width: 6%;">
								<span v-show="p.id == 0">-</span>
								<span v-show="p.id != 0" :title="p.alert !== '-' ? p.alert : '-'">{{ p.alert !== '-' ? p.alert : '-' }}{{ p.alert !== '-' ? p.unit : '' }}</span>
							</li>
							<li style="width: 6%;text-align: center;">
								<span v-show="p.id == 0">-</span>
								<span v-show="p.id != 0 && p.over == 0" class="blue">正常</span>
								<span v-show="p.id != 0 && p.over == 2" class="yellow">预警</span>
								<span v-show="p.id != 0 && p.over == 1" class="red">报警</span>
							</li>
							<li style="width: 10%;" class="compile">
								<i :style="{ opacity: p.id != 0 ? 1 : 0 }" @click="preview(p,$event)" title="预览数据" class="icon iconfont icon-yulan outline blue"></i>
								<i @click="removeList(p, i)" title="移出列表" class="iconfont icon-yichu delete red"></i>
							</li>
						</el-checkbox-group>
					</ul>
				</el-scrollbar>
				<noData v-if="listData.length == 0" />
			</div>

			<div class="tableFooter">
				<Button class="downloadTable" type="downloadTable" :button-callback="buttonCallback" text="下载表单" />
				<Button class="downloadData" type="downloadData" :disabled="!judgment('authorityList',311,that)" :button-callback="buttonCallback" text="下载数据" />
				<Button class="createWord" type="save" :disabled="!judgment('authorityList',310,that)" :button-callback="buttonCallback" text="保存报告" />
				<Button class="createWord" type="preview" :disabled="!judgment('authorityList', 310, that)" :button-callback="buttonCallback" text="预览报告" />
				<Button class="deleteData" type="deleteData" :disabled="!judgment('authorityList',309,that)" :button-callback="buttonCallback" text="删除数据" />
			</div>
		</div>
	</div>
</template>

<script>
import historyExp from './historyExp.js';

export default historyExp;
</script>

<style scoped lang="scss">
@import './historyExp.scss';
</style>
