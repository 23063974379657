<template>
  <div class="statementPage">
    <div id="tableContainer">
      <el-scrollbar :native="false" :noresize="false" tag="div">
        <div class="tableWidth" id="statement">
          <div class="cover" style="overflow: hidden;width: 100%;" v-if="form.cover" :data-status="form.cover">
            <div class="coverPage1" style="width: 100%;height: 1000px;position: relative;margin-bottom: 50px;overflow: hidden">
              <div id="background" style="width: 100%;height: 100%;float: left;overflow: hidden;"></div>
              <div class="content" style="position: absolute;left: 0;width: 100%;height: 100%;top: 0">
                <h1 style="font-size: 32px;text-align: center;line-height: 32px; margin: 200px 0 150px 0;">{{ form.title }}</h1>
                <p id="cma" style="width: 267.5px; height: 226.5px;overflow: hidden;margin: 0 auto"></p>
                <h5 style="font-size: 24px;margin-top: 200px;text-align: center;font-weight: normal;">{{ form.enterprise }}</h5>
                <p style="font-size: 18px;text-align: center;margin-top: 50px">2020年7月29日</p>
              </div>
            </div>
            <div class="coverPage2" style="width: 100%;height: 1000px;position: relative;margin-bottom: 50px;overflow: hidden">
              <p style="height: 50px;width: 100%;overflow:hidden;"></p>
              <h4 style="margin-top: 30px;text-align: center;font-size: 24px">{{ form.project }}</h4>
              <h4 style="margin-top: 30px;text-align: center;font-size: 26px;margin-bottom: 100px">{{ form.title }}</h4>
              <p style="padding: 0 50px;color: red;line-height: 40px;text-align: center">委托单位：<span style="text-decoration: underline" v-html="strJoinBlank(form.entrust, 49, '&nbsp;')"></span></p>
              <p style="padding: 0 50px;color: red;line-height: 40px;text-align: center">合同编号：<span style="text-decoration: underline" v-html="strJoinBlank(form.contract, 49, '&nbsp;')"></span></p>
              <p style="padding: 0 50px;color: red;line-height: 40px;text-align: center">委托内容：<span style="text-decoration: underline" v-html="strJoinBlank(form.content, 49, '&nbsp;')"></span></p>
              <p style="padding: 0 50px;color: red;line-height: 40px;text-align: center">报告期数：<span style="text-decoration: underline" v-html="strJoinBlank(form.periods, 49, '&nbsp;')"></span></p>
              <ul style="overflow:hidden;padding: 0 50px;margin-top: 50px">
                <li style="float: left;list-style: none">检测：<span style="width: 100px; height: 41px;display: inline-block" id="signature1"></span></li>
                <li style="float: left;list-style: none;margin: 0 40px">审核：<span style="width: 100px; height: 41px;display: inline-block" id="signature2"></span></li>
                <li style="float: left;list-style: none">批准：<span style="width: 100px; height: 41px;display: inline-block" id="signature3"></span></li>
              </ul>
              <p style="text-align: right;padding: 0 50px;margin-top: 10px;">（检测专用章）</p>
              <p style="padding: 0 50px;margin-top: 20px">报告说明：</p>
              <ul style="padding: 0 50px;font-size: 12px;line-height: 22px;">
                <li style="list-style: none">1.	报告未盖本单位“检测专用章”无效；</li>
                <li style="list-style: none">2.	报告无批准签名无效，报告涂改无效；</li>
                <li style="list-style: none">3.	本结果仅对所检测样品有效，证书未经本检验检测机构批准，不得部分复印；</li>
                <li style="list-style: none">4.	对检测报告有异议，应及时向本单位提出；</li>
                <li style="list-style: none">5.	有关检测检验数据未经本单位或有关行政主管部门允许，任何单位不得擅自向社会发布信息。</li>
              </ul>

              <p style="padding: 0 50px;text-align: right;margin-top: 100px">{{ form.enterprise }}</p>
              <p style="padding: 0 50px;text-align: right;margin-top: 20px">{{ form.date }}</p>
            </div>
          </div>

          <table border="0" cellspacing="0" cellpadding="0" style="border-top: 1px solid #000;border-left: 1px solid #000;width: 100%">
            <tr>
              <td class="title" colspan="2" style="width:120px;line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                {{ form.title1 }}
              </td>
              <td class="title" colspan="2" style="line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                {{ form.gatherTime }}
              </td>
            </tr>
            <tr>
              <td class="title" style="width:120px;line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                工程名称
              </td>
              <td class="center" colspan="3" style="line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                {{ form.project }}
              </td>
            </tr>
            <tr>
              <td class="title" style="width:120px;line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                监测项目
              </td>
              <td class="center" style="line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                {{ form.parameter }}
              </td>
              <td class="title" style="width:120px;line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                监测方法
              </td>
              <td class="center" style="line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                {{ form.type }}
              </td>
            </tr>
            <tr>
              <td class="title" style="width:120px;line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                监测依据
              </td>
              <td class="center" style="line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                {{ form.regulation }}
              </td>
              <td class="title" style="width:120px;line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                监测仪器
              </td>
              <td class="center" style="line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                {{ form.device }}
              </td>
            </tr>
            <tr>
              <td class="title" style="width:120px;line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                安全允许值
              </td>
              <td class="center" colspan="3" style="line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                {{ form.maxCVal }}cm/s
              </td>
            </tr>
            <tr>
              <td class="title" style="width:120px;line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                文件数量
              </td>
              <td class="center" colspan="3" style="line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                {{ form.count }}个
              </td>
            </tr>
            <tr>
              <td class="title" style="width:120px;line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                超标点数
              </td>
              <td class="center" colspan="3" style="line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                {{ form.overproof }}个
              </td>
            </tr>
            <tr>
              <td class="title" style="width:120px;line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                比对结果
              </td>
              <td colspan="3" style="line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                {{ form.conclusion }}
              </td>
            </tr>
            <tr>
              <td class="title" style="width:120px;line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                爆破参数
              </td>
              <td colspan="3" style="line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                {{ form.remarks }}
              </td>
            </tr>
          </table>
          <table border="0" cellspacing="0" cellpadding="0" style="border-top: 1px solid #000;border-left: 1px solid #000;margin-top: 50px;width: 100%">
            <tr>
              <td class="center" colspan="7" style="line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                监测数据
              </td>
            </tr>
            <tr>
              <td class="center" style="width: 15%;line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                测点
              </td>
              <td class="center" style="width: 15%;line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                文件
              </td>
              <td class="nestTable center" colspan="3" style="width: 50%;line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;text-align: center">
                <table style="width: 100%;height: 100%">
                  <tr>
                    <td class="center" style="width: 30%;line-height: 30px;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                      测振方向
                    </td>
                    <td class="center" style="width: 35%;line-height: 30px;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                      峰值振速[V](cm/s)
                    </td>
                    <td class="center" style="width: 35%;line-height: 30px;box-sizing: border-box;padding: 0 10px;text-align: center">
                      主频[F](Hz)
                    </td>
                  </tr>
                </table>
              </td>
              <td class="center" style="width: 10%;line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                控制值[V](cm/s)
              </td>
              <td class="center" style="width: 10%;line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                备注
              </td>
            </tr>
            <tr v-for="d in tableData">
              <td class="center" style="width: 15%;line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                {{ d.stationName }}
              </td>
              <td class="center" style="width: 15%;line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                <p>{{ d.fileDate }}</p>
                <p>{{ d.fileTime }}</p>
              </td>
              <td colspan="3" class="nestTable center" style="width: 45%;line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;text-align: center">
                <table style="width: 100%;height: 100%">
                  <tr>
                    <td class="center" style="width: 30%;line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                      X
                    </td>
                    <td class="center" style="width: 35%;line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                      {{ d.peakX }}
                    </td>
                    <td class="center" style="width: 35%;line-height: 30px;border-bottom: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                      {{ d.freqX }}
                    </td>
                  </tr>
                  <tr>
                    <td class="center" style="width: 30%;line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                      Y
                    </td>
                    <td class="center" style="width: 35%;line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                      {{ d.peakY }}
                    </td>
                    <td class="center" style="width: 35%;line-height: 30px;border-bottom: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                      {{ d.freqY }}
                    </td>
                  </tr>
                  <tr>
                    <td class="center" style="width: 30%;line-height: 30px;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                      Z
                    </td>
                    <td class="center" style="width: 35%;line-height: 30px;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                      {{ d.peakZ }}
                    </td>
                    <td class="center" style="width: 35%;line-height: 30px;box-sizing: border-box;padding: 0 10px;text-align: center">
                      {{ d.freqZ }}
                    </td>
                  </tr>
                </table>
              </td>
              <td class="center" style="width: 15%;line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                {{ d.CVal }}
              </td>
              <td class="center" style="width: 10%;line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                {{ d.remark }}
              </td>
            </tr>
          </table>

          <p style="margin: 50px 0 20px;text-align: center">振动测试速度波形图</p>
          <table border="0" cellspacing="0" cellpadding="0" style="border-top: 1px solid #000;border-left: 1px solid #000;width: 100%">
            <tr v-for="f in tableData">
              <td class="nestTable">
                <table style="width: 100%">
                  <tr>
                    <td class="center" style="width: 80%;line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                      <p>{{ f.stationName }}</p>
                      <p>{{ f.stationAddress }}</p>
                    </td>
                    <td class="center" style="width: 20%;line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;text-align: center">
                      <p>{{ f.deviceName }}</p>
                      <p>{{ f.filename }}</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="center" style="width: 80%;box-sizing: border-box;text-align: center;border-bottom: 1px solid #000;border-right: 1px solid #000;">
                      <img :src="f.image" alt="chart" style="width: 450px;">
                    </td>
                    <td class="center" style="width: 20%;text-align: left;position: relative;line-height: 30px;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                      <div style="position: absolute;top: 0;line-height: 10px">
                        <p class="title" style="text-align: left;font-weight: bold">X方向</p>
                        <p class="text" style="color: #333">幅值[v]</p>
                        <p class="text" style="color: #333">{{ f.peakX }}cm/s</p>
                        <p class="text" style="color: #333">主频[F]</p>
                        <p class="text" style="color: #333">{{ f.freqX }}Hz</p>
                        <p class="title" style="text-align: left;font-weight: bold">Y方向</p>
                        <p class="text" style="color: #333">幅值[V]</p>
                        <p class="text" style="color: #333">{{ f.peakY }}cm/s</p>
                        <p class="text" style="color: #333">主频[F]</p>
                        <p class="text" style="color: #333">{{ f.freqY }}Hz</p>
                        <p class="title" style="text-align: left;font-weight: bold">Z方向</p>
                        <p class="text" style="color: #333">幅值[V]</p>
                        <p class="text" style="color: #333">{{ f.peakZ }}cm/s</p>
                        <p class="text" style="color: #333">主频[F]</p>
                        <p class="text" style="color: #333">{{ f.freqZ }}Hz</p>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
        <div class="btnContainer">
          <p @click="exportToPDF">
            <i class="icon iconfont icon-daochu1"></i>
            <span>导出PDF</span>
          </p>
          <p @click="exportWord">
            <i class="icon iconfont icon-daochu1"></i>
            <span>导出word</span>
          </p>
          <p @click="saveWord">
            <i class="icon iconfont icon-baocun-tianchong"></i>
            <span>保存</span>
          </p>
          <p @click="goBack">
            <i style="font-size: 30px;margin: 2px 0" class="icon iconfont icon-ai207"></i>
            <span>返回</span>
          </p>
          <p @click="closeLayer">
            <i class="icon iconfont icon-quxiao"></i>
            <span>取消</span>
          </p>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import statement from "./statement.js"

export default statement;
</script>

<style scoped lang="scss">
@import "statement";
</style>

