<template>
	<div class="briefReportPage">
		<div id="tableContainer">
			<el-scrollbar :native="false" :noresize="false" tag="div">
				<div class="tableWidth" id="briefReport">
					<table border="0" cellspacing="0" cellpadding="0" style="border-top: 1px solid #000;border-left: 1px solid #000;width: 100%">
						<tr style="line-height: 30px">
							<td rowspan="5" style="width: 30%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
								<p style="font-size: 16px;text-align: center;text-align: center">{{ form.name }}</p>
								<p class="center" style="text-align: center">{{ form.filename }}</p>
							</td>
							<td colspan="2" style="width: 70%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
								项目：{{ form.project }}
							</td>
						</tr>
						<tr style="line-height: 30px">
							<td colspan="2" style="width: 70%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
								名称/位置：{{ form.location }}
							</td>
						</tr>
						<tr style="line-height: 30px">
							<td colspan="2" style="width: 70%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
								测点编号：{{ form.deviceId }}[{{ form.sampling }}ksps\{{ form.delayed }}s]
							</td>
						</tr>
						<tr style="line-height: 30px">
							<td colspan="2" style="width: 70%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
								企业：{{ form.enterprise }}
							</td>
						</tr>
						<tr style="line-height: 30px">
							<td colspan="2" style="width: 70%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
								签字：{{ form.operator }}
							</td>
						</tr>
					</table>

					<table border="0" cellspacing="0" cellpadding="0" style="border-left: 1px solid #000;width: 100%">
						<tr>
							<td colspan="2" style="width: 70%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
								<img :src="form.imageX" style="width: 500px;height: 222px" alt="" />
							</td>
							<td style="width: 30%;position: relative;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
								<div style="position: absolute;top: 0;line-height: 10px;">
									<p>X方向</p>
									<p>幅值[V]</p>
									<p>{{ form.peakX }}{{form.units}}</p>
									<p>主频[F]</p>
									<p>{{ form.freqX }}Hz</p>
									<p>峰值时刻[t]</p>
									<p>{{ form.maxXTime }}s</p>
								</div>
							</td>
						</tr>
						<tr>
							<td colspan="2" style="width: 70%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
								<img :src="form.imageY" style="width: 500px;height: 222px" alt="" />
							</td>
							<td style="width: 30%;position: relative;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
								<div style="position: absolute;top: 0;line-height: 10px">
									<p>Y方向</p>
									<p>幅值[V]</p>
									<p>{{ form.peakY }}{{form.units}}</p>
									<p>主频[F]</p>
									<p>{{ form.freqY }}Hz</p>
									<p>峰值时刻[t]</p>
									<p>{{ form.maxYTime }}s</p>
								</div>
							</td>
						</tr>
						<tr>
							<td colspan="2" style="width: 70%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
								<img :src="form.imageZ" style="width: 500px;height: 222px" alt="" />
							</td>
							<td style="width: 30%;position: relative;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
								<div style="position: absolute;top: 0;line-height: 10px">
									<p>Z方向</p>
									<p>幅值[V]</p>
									<p>{{ form.peakZ }}{{form.units}}</p>
									<p>主频[F]</p>
									<p>{{ form.freqZ }}Hz</p>
									<p>峰值时刻[t]</p>
									<p>{{ form.maxZTime }}s</p>
								</div>
							</td>
						</tr>
					</table>

					<table border="0" cellspacing="0" cellpadding="0" style="border: 1px solid #000;width: 100%;border-top: 0">
						<tr style="width: 100%;line-height: 30px">
							<td colspan="3" style="padding: 10px">爆破参数：{{ form.focus }}</td>
						</tr>
					</table>
				</div>
				<div class="btnContainer">
					<!-- <p @click="exportToPDF">
						<i class="icon iconfont icon-daochu"></i>
						<span>导出PDF</span>
					</p> -->
					<p @click="exportWord">
						<i class="icon iconfont icon-daochu"></i>
						<span>导出word</span>
					</p>
					<!--          <p @click="goBack">-->
					<!--            <i style="font-size: 30px;margin: 2px 0" class="icon iconfont icon-ai207"></i>-->
					<!--            <span>返回</span>-->
					<!--          </p>-->
					<p @click="closeLayer">
						<i class="icon iconfont el-icon-close close"></i>
						<span>关闭</span>
					</p>
				</div>
			</el-scrollbar>
		</div>
	</div>
</template>

<script>
import briefReport from './briefReport.js';
export default briefReport;
</script>

<style scoped lang="scss">
@import 'briefReport';
</style>
