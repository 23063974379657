<template>
	<div class="chart-box">
		<div class="head-box">
			<el-select class="margin-r" v-model="pointSelect" size="small" filterable placeholder="请选择测点" @change="queryInfo(false)">
				<el-option label="全部" value="all"></el-option>
				<el-option v-for="(item, index) in pointOption" :key="index" :label="item.name" :value="item.id">
					<!-- :hide-after="300" -->
					<el-tooltip effect="light" :content="item.address" placement="right" :enterable="false">
						<span>{{ item.name + '（' + item.address + '）' }}</span>
					</el-tooltip>
				</el-option>
			</el-select>
			<!-- <el-date-picker
				class="margin-r"
				v-model="time"
				type="daterange"
				size="small"
				range-separator="至"
				start-placeholder="开始日期"
				end-placeholder="结束日期"
				value-format="yyyy-MM-dd"
				@change="queryInfo(true)"
			></el-date-picker> -->
			<div class="time-select-box margin-r">
				<el-date-picker v-model="time.begin" type="date" size="small" placeholder="选择开始日期" value-format="yyyy-MM-dd" @change="timeQueryInfo"></el-date-picker>
				<span class="text">至</span>
				<el-date-picker v-model="time.end" type="date" size="small" placeholder="选择结束日期" value-format="yyyy-MM-dd" @change="timeQueryInfo"></el-date-picker>
			</div>
			<el-radio-group v-model="dataType" size="small" @change="switchPageData">
				<el-radio-button label="1">
					<el-tooltip effect="light" content="三向的矢量合成值" placement="top" :enterable="false"><span>合成值</span></el-tooltip>
				</el-radio-button>
				<el-radio-button label="2">
					<el-tooltip effect="light" content="三向中最大振幅值" placement="top" :enterable="false"><span>三向值</span></el-tooltip>
				</el-radio-button>
			</el-radio-group>
			<div class="switch-box">
				<p>
					报警值：
					<span class="value">{{ alert }}&nbsp;</span>
					{{ unit }}
				</p>
				<!-- <el-switch v-model="alertShow" inline-prompt active-text="是" inactive-text="否" :active-value="true" :inactive-value="false" @change="switchAlert" /> -->
				<el-button class="refreshBut" plain round type="success" size="small" @click="samplingRefresh" title="刷新图表"><span class="el-icon-refresh"></span></el-button>
			</div>
			<!-- <el-button type="primary" size="small" @click="samplingQuery">查询</el-button> -->
		</div>
		<!-- 注释 -->
		<div class="annotation-box">
			<span class="n-text">注：</span>
			<p v-show="dataType == '1'">
				<span class="annotation-title">主频：</span>
				三向主频中的最小值
			</p>
			<p v-show="dataType == '2'">
				<span class="annotation-title">主频：</span>
				最大振幅对应的主频
			</p>
			<div class="myChart-legend">
				<div class="legend-box" v-for="(i, index) in myChartLegend" :key="index">
					<div class="square" :style="{ background: i.color }"></div>
					<span>{{ i.name }}</span>
				</div>
			</div>
		</div>
		<div class="echarts-box" id="chartEchartsBox">
			<div :class="oneSamplingShow ? '' : 'small'" id="graph"></div>
			<div :class="oneSamplingShow ? 'sampling-box ' : 'sampling-box big'" v-show="twoButShow">
				<el-button
					class="open-stow"
					type="primary"
					size="small"
					plain
					circle
					:icon="oneSamplingShow ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
					:title="oneSamplingShow ? '折叠' : '展开'"
					@click="openOrStow"
				></el-button>
				<div id="sampling"></div>
			</div>
		</div>
		<!-- 列表数据预览三个柱状图 -->
		<el-dialog title="预览" custom-class="preview-dialog" :visible.sync="dialogPreview" :show-close="false" @close="handleClose('preview')">
			<template slot="title">
				<div class="baseInfo">
					<div class="left">{{ fileBaseData.startTime }}</div>
					<ul class="center">
						<li :title="'测点编号：' + fileBaseData.pointName">测点编号：{{ fileBaseData.pointName }}</li>
						<li :title="'测点参数：' + fileBaseData.deviceParam">测点参数：{{ fileBaseData.deviceParam ? fileBaseData.deviceParam : '-' }}</li>
						<li :title="'结束时间：' + fileBaseData.endTime">结束时间：{{ fileBaseData.endTime ? fileBaseData.endTime : '-' }}</li>
						<li :title="'名称/位置：' + fileBaseData.address">名称/位置：{{ fileBaseData.address ? fileBaseData.address : '-' }}</li>
						<li :title="'设备编号：' + fileBaseData.deviceNum">设备编号：{{ fileBaseData.deviceNum ? fileBaseData.deviceNum : '-' }}</li>
					</ul>
					<div class="right">
						<el-button type="primary" size="mini" @click="briefingClick">简报</el-button>
						<el-button type="success" size="mini" @click="associated">关联</el-button>
						<el-button type="warning" size="mini" @click="dialogPreview = false">关闭</el-button>
					</div>
				</div>
			</template>
			<div class="content-box">
				<div class="echarts-box" id="echartsBox"><div class="barEcharts" id="samplingBarEcharts"></div></div>
			</div>
		</el-dialog>
		<!-- 简报设置 -->
		<el-dialog title="生成简报" custom-class="generate-dialog" center :visible.sync="dialogGenerate" @close="handleClose('generate')">
			<div class="content-box">
				<el-form label-position="right" label-width="80px" :model="generateInfo" size="mini">
					<el-form-item label="项目名称"><el-input v-model="generateInfo.name"></el-input></el-form-item>
					<el-form-item label="企业名称"><el-input v-model="generateInfo.name"></el-input></el-form-item>
					<el-form-item label="测点编号"><el-input v-model="generateInfo.name"></el-input></el-form-item>
					<el-form-item label="名称位置"><el-input v-model="generateInfo.name"></el-input></el-form-item>
					<el-form-item label="爆破参数"><el-input v-model="generateInfo.name"></el-input></el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer"><el-button type="primary" size="medium" @click="generateBriefingDetermine">确 定</el-button></div>
		</el-dialog>
		<!-- 简报预览 -->
		<el-dialog title="简报预览" custom-class="briefing-dialog" :visible.sync="dialogBriefing" @close="handleClose('briefing')">
			<div class="content-box">
				<div class="left-box">
					<vue-scroll>
						<table class="text-table" cellspacing="0" v-if="briefingType == 1">
							<tbody>
								<tr>
									<td colspan="4" class="title">
										{{ monitoringBriefing.fileName }}
										<span class="title-color">监测简报</span>
									</td>
								</tr>
								<tr>
									<td class="name">开始时间</td>
									<td class="data">{{ monitoringBriefing.startTime }}</td>
									<td class="name">截止时间</td>
									<td class="data">{{ monitoringBriefing.endTime }}</td>
								</tr>
								<tr>
									<td class="name">监测时间</td>
									<td class="data">{{ monitoringBriefing.dayNum }}天</td>
									<td class="name">测点数</td>
									<td class="data">{{ monitoringBriefing.pointNum }}</td>
								</tr>
								<tr>
									<td class="name">报警值</td>
									<td class="data">{{ monitoringBriefing.threshold + monitoringBriefing.unit }}次</td>
									<td class="name">超标天数</td>
									<td class="data">{{ monitoringBriefing.alertNum }}天</td>
								</tr>
								<tr>
									<td class="text-name">结论</td>
									<td class="text" colspan="3">
										从{{ monitoringBriefing.startTime }}到{{ monitoringBriefing.endTime }}，共布置{{ monitoringBriefing.pointNum }}个测点，监测{{
											monitoringBriefing.dayNum
										}}天，其中，无触发数据{{ monitoringBriefing.noTrigger }}天，有触发数据{{ monitoringBriefing.trigger }}天，有触发数据且超过报警值{{
											monitoringBriefing.exceed
										}}天。
									</td>
								</tr>
								<tr>
									<td class="img-text" colspan="4">
										<!-- <p>{{ briefingType == 1 ? '柱状图' : '波形图' }}</p> -->
										<div class="point-name-box" v-html="monitoringBriefing.point"></div>
										<div class="legend monitoringBriefing" v-html="monitoringBriefing.legend"></div>
										<img :src="briefingImg" />
									</td>
								</tr>
								<tr>
									<td class="signature-box" colspan="4"><span>签名：</span></td>
								</tr>
								<tr>
									<td class="signature-box" colspan="4"><span>备注：</span></td>
								</tr>
							</tbody>
						</table>
						<table class="text-table" cellspacing="0" v-else>
							<tbody>
								<tr>
									<td colspan="4" class="title">
										{{ daily.fileName }}
										<span class="title-color">监测日报</span>
									</td>
								</tr>
								<tr>
									<td class="name">监测时间</td>
									<td class="data">{{ daily.startTime }}</td>
									<td class="name">测点数</td>
									<td class="data">{{ daily.pointNum }}</td>
								</tr>
								<tr>
									<td class="name">报警值</td>
									<td class="data">{{ daily.threshold + daily.unit }}</td>
									<td class="name">超标次数</td>
									<td class="data">{{ daily.alertNum }}次</td>
								</tr>
								<tr>
									<td class="text-name">结论</td>
									<td class="text" colspan="3">
										在{{ daily.startTime }}的监测中，共布置{{ daily.pointNum }}个测点，获取触发数据{{ daily.trigger }}条，超标数据{{ daily.alertNum }}条。
									</td>
								</tr>
								<tr>
									<td class="img-text" colspan="4">
										<!-- <p>{{ briefingType == 1 ? '柱状图' : '波形图' }}</p> -->
										<div class="legend" v-html="daily.legend"></div>
										<img :src="briefingImg" />
									</td>
								</tr>
								<tr>
									<td class="signature-box" colspan="4"><span>签名：</span></td>
								</tr>
								<tr>
									<td class="signature-box" colspan="4"><span>备注：</span></td>
								</tr>
							</tbody>
						</table>
					</vue-scroll>
				</div>
				<div class="right-box">
					<div class="but-box">
						<p @click="wordDownClick">
							<i class="icon iconfont icon-daochu"></i>
							<span>导出Word</span>
						</p>
					</div>
					<div class="but-box">
						<p @click="dialogBriefing = false">
							<i class="icon iconfont el-icon-close close"></i>
							<span>关闭</span>
						</p>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import JSZipUtils from 'jszip-utils';
import { createReport } from 'docx-templates';

export default {
	name: '',
	props: {
		authorityList: {
			type: Array,
			default: () => {
				return [];
			}
		},
		pointOption: {
			type: Array,
			default: () => {
				return [];
			}
		}
	},
	data() {
		return {
			time: {
				begin: '',
				end: ''
			},
			dataType: '2', //抽样数据数据类型
			alertShow: false, // 是否显示报警线
			pointSelect: 'all', //下拉框选择的测点id
			pointIds: [], // 请求曲线图数据的测点id
			pointInfo: {}, // 请求曲线图数据的测点信息
			list: {}, //图一曲线图数据
			twoList: [], //图二曲线图数据
			twoTimeList: {}, // 图二曲线图数据（以时间为key，方便图二点击事件取值）
			oneSamplingShow: true, //图一是否显示
			twoButShow: false, //图二展开折叠按钮是否显示
			myChart: null,
			myChartSampling: null,
			tooltipData: {}, //图一图标鼠标移入提示数据
			myChartLegend: [
				{
					name: '无触发数据',
					color: '#8f9fc0'
				},
				{
					name: '有触发数据',
					color: '#67c23a'
				},
				{
					name: '有触发数据且超过报警值',
					color: '#d40000'
				}
			], // 图一图例信息
			twoTooltipData: {}, //图二图标鼠标移入提示数据
			oneYMax: 0, // 图一y轴最大值
			twoYMax: 0, // 图二y轴最大值
			twoQuery: {}, // 图二数据请求参数
			alert: 0, //图表报警线
			unit: 'cm/s', // 页面数据单位
			tagLineData: {}, //图表标记线数据
			curveData: {}, // 当前选中的曲线测点数据
			myChartClick: true, // 图一是否允许执行点击
			myChartSamplingClick: true, // 图二是否允许执行点击
			dialogPreview: false, //列表数据预览弹窗
			fileBaseData: {
				startTime: '',
				pointName: '',
				deviceParam: '',
				address: '',
				deviceNum: '',
				endTime: ''
			},
			legendData: {
				xmax: '',
				xtime: '',
				ymax: '',
				ytime: '',
				zmax: '',
				ztime: ''
			},
			colorALLArr: [], // 曲线图全部颜色
			colorArr: {}, // 曲线图全部颜色
			dialogGenerate: false, //生成简报弹窗是否显示
			generateInfo: {
				name: ''
			}, //生成简报弹窗信息
			dialogBriefing: false, //简报预览
			briefingType: '', // 简报生成类型（图一还是图二）
			briefingImg: '', //简报预览图片
			proportion: 0, // 简报预览图片比例
			myChartSamplingTime: [], // 图二生成简报存储的时间
			myChartSamplingAllNum: 0, //图二生成简报存储的采集点数
			myChartSamplingAlertNum: 0, //图二生成简报存储的超标点数
			monitoringBriefing: {
				fileName: '', //文件名（项目名）
				startTime: '', //开始时间
				endTime: '', //截止时间
				threshold: 0, //超标阈值
				pointNum: 0, //测点数
				allNum: 0, //采集点数
				alertNum: 0, //超标点数
				dayNum: 0, //监测时间（天数）
				noTrigger: 0, // 无触发值天数
				trigger: 0, // 有触发值天数
				exceed: 0, // 有触发值且超过报警值
				point: '', //全部测点名（html片段）
				legend: '', //图例（html片段）
				unit: 'cm/s' // 单位
			}, // 简报展示数据(监测简报，图一)
			daily: {
				fileName: '', //文件名（项目名）
				startTime: '', //开始时间
				threshold: 0, //超标阈值
				pointNum: 0, //测点数
				alertNum: 0, //超标点数
				unit: 'cm/s', // 单位
				trigger: 0, // 有触发值天数
				legend: '' //图例（html片段）
			} // 简报展示数据(监测日报，图二)
		};
	},
	created() {
		this.alert = 0;
		this.getColorArr();
		this.getHorizontalLineData();
	},
	mounted() {},
	computed: {},
	methods: {
		// 切换数据类型
		switchPageData() {
			this.twoList = [];
			if (this.myChartSampling) {
				this.myChartSampling.clear();
			}
			this.queryInfo(false);
		},
		// 获取曲线图颜色
		getColorArr() {
			$.ajax({
				url: this.constData.colorFileUrl,
				success: (res) => {
					// // console.log(res)
					this.colorALLArr = res.data;
				}
			});
		},
		queryInfo(time = false) {
			if (!time) {
				for (let k in this.time) {
					this.time[k] = '';
				}
			}
			this.samplingQuery();
		},
		timeQueryInfo() {
			if (this.time.begin == '' || this.time.end == '') {
				return;
			}
			let begin = new Date(this.time.begin).getTime();
			let end = new Date(this.time.end).getTime();
			if (begin > end) {
				this.$message({
					showClose: true,
					message: '开始时间应当小于结束时间！',
					type: 'warning'
				});
				return;
			}
			this.samplingQuery();
		},
		// 查询图一数据
		samplingQuery() {
			let data = [];
			if (this.pointSelect == 'all') {
				this.pointOption.forEach((i) => {
					let obj = {
						id: i.id,
						name: i.name
					};
					for (let k in this.time) {
						if (this.time[k] != '') {
							obj[k] = this.time[k];
						}
					}
					data.push(obj);
				});
			} else {
				try {
					this.pointOption.forEach((i) => {
						if (this.pointSelect == i.id) {
							let obj = {
								id: i.id,
								name: i.name
							};
							for (let k in this.time) {
								if (this.time[k] != '') {
									obj[k] = this.time[k];
								}
							}
							data.push(obj);
							throw '';
						}
					});
				} catch (e) {
					//TODO handle the exception
				}
			}

			this.getChartData(data);
			setTimeout(() => {
				if (this.twoQuery.time) {
					this.getDataTwo(this.twoQuery.time);
				}
			}, 300);
		},
		// 获取图一数据
		getChartData(value) {
			var axiosArr = [];
			var name = [];
			this.pointIds = [];
			this.pointInfo = {};
			value.forEach((i, index) => {
				let obj = {};
				for (let k in i) {
					if (k != 'name') {
						obj[k] = i[k];
					} else {
						name.push(i[k]);
					}
				}
				obj.type = this.dataType;
				this.pointInfo[i.id] = i.name;
				this.pointIds.push(i.id);
				axiosArr.push(this.axios.post('web2/data/odnr/gfsdata', obj));
			});
			this.axios.all(axiosArr).then((res) => {
				this.list = {};
				// // console.log(res)
				let time = [];
				res.forEach((item, index) => {
					if (item.status) {
						this.list[name[index]] = item.data;
						this.colorArr[name[index]] = this.colorALLArr[index];
						item.data.forEach((el) => {
							let hour = new Date(el.hour).getTime();
							if (!time.includes(hour)) {
								time.push(hour);
							}
						});
					}
				});
				if (time.length) {
					let min = Math.min.apply(null, time);
					let max = Math.max.apply(null, time);
					this.time.begin = new Date(min).format('yyyy-MM-dd');
					this.time.end = new Date(max).format('yyyy-MM-dd');
				}
				// console.log(this.list);
				if (JSON.stringify(this.list) != '{}') {
					this.drawChart();
				} else {
					this.$message({
						message: '暂无数据',
						type: 'warning'
					});
					// if(this.myChart != null){
					// 	this.myChart.dispose();
					// }
				}
			});
		},
		// 获取图二数据 web2/data/odnr/gdsdata ids:测点id hour:时间
		getDataTwo(time = '') {
			let data = {
				ids: this.pointIds.toString(),
				hour: time,
				type: this.dataType
			};
			this.twoList = [];
			this.twoTimeList = {};
			this.axios.post('web2/data/odnr/gdsdata', data).then((res) => {
				this.twoList = [];
				this.twoTimeList = {};
				if (res.status) {
					this.twoList = res.data;
					// console.log(this.twoList);
					res.data.forEach((item) => {
						item.array.forEach((el) => {
							if (!(el.minute in this.twoTimeList)) {
								this.twoTimeList[el.minute] = [{ ...el, id: item.id }];
							} else {
								this.twoTimeList[el.minute].push({ ...el, id: item.id });
							}
						});
					});
					this.drawSampling();
					this.myChartClick = true;
				}
			});
		},
		// 顶部图二生成简报请求数据
		getBriefingDataTwo() {
			if (this.pointOption.length == 0) {
				this.$message({
					showClose: true,
					message: '此项目无数据！',
					type: 'warning'
				});
				return;
			}
			let data = {
				ids: '',
				begin: new Date(this.myChartSamplingTime[0]).format('yyyyMMdd'),
				type: this.dataType
			};
			this.daily.fileName = this.$route.query.name;
			this.daily.startTime = this.myChartSamplingTime[0].substring(0, 10);
			this.daily.threshold = this.alert;
			this.daily.pointNum = this.pointSelect == 'all' ? this.pointOption.length : 1;

			let ids = [];
			// 简报图例
			this.daily.legend = `<meta charset="UTF-8"><body><p>`;
			if (this.pointSelect == 'all') {
				this.pointOption.forEach((i) => {
					ids.push(i.id);
					this.daily.legend += `<span class="legend-span" style="color: ${
						this.colorArr[i.name]
					};font-size: 18px;font-family: '宋体';">▅</span> <span class="name-t" style="font-size: 14px;margin-right: 10px;">${i.name}(${i.address})；  </span>`;
				});
				data.ids = ids.toString();
			} else {
				try {
					this.pointOption.forEach((i) => {
						if (i.id == this.pointSelect) {
							this.daily.legend += `<span class="legend-span" style="color: ${
								this.colorArr[i.name]
							};font-size: 18px;font-family: '宋体';">▅</span> <span class="name-t" style="font-size: 14px;margin-right: 10px;">${i.name}(${i.address})；  </span>`;
							throw '';
						}
					});
				} catch (e) {
					//TODO handle the exception
				}
				data.ids = this.pointSelect;
			}
			this.daily.legend += `<p></body>`;
			this.axios.post('web2/data/odnr/gsdnum', data).then((res) => {
				if (res.status) {
					this.daily.trigger = res.data.allNum;
					this.daily.alertNum = res.data.alertNum;
					this.dialogBriefing = true;
				}
			});
		},
		// 切换报警显示
		switchAlert() {
			this.drawChart();
			if (this.twoSamplingShow) {
				this.drawSampling();
			}
		},
		// 抽样数据刷新
		samplingRefresh() {
			var data = [];
			for (let k in this.pointInfo) {
				data.push({
					id: k,
					name: this.pointInfo[k]
				});
			}
			this.getChartData(data);
			if ('time' in this.twoQuery) {
				this.getDataTwo(this.twoQuery.time);
			}
			// // console.log(data)
		},
		// 获取图表报警线、触发值数据  web2/data/odnr/gtaav
		getHorizontalLineData() {
			let data = {
				id: this.$route.query.id
			};
			this.tagLineData = {};
			this.alert = 0;
			this.axios.post('web2/data/odnr/gtaav', data).then((res) => {
				if (res.status) {
					res.data.forEach((item) => {
						this.tagLineData[item.id] = item;
					});
					if (res.data.length > 0) {
						this.alert = res.data[0].alert;
						this.unit = res.data[0].unit;
					}
				}
			});
		},
		// 图二曲线点击获取数据 web2/data/odnr/gpmfdata
		getBriefingTwo(info = {}) {
			if (JSON.stringify(info) == '{}') {
				return;
			} else if (!info.id) {
				return;
			}
			let data = {
				id: '',
				minute: ''
			};
			for (let k in data) {
				if (k in info) {
					data[k] = info[k];
				}
			}
			this.axios.post('web2/data/odnr/gpmfdata', data).then((res) => {
				if (res.status) {
					let itemData = {
						id: data.id
					};
					this.$parent.samplingFilePreview(itemData, res);
				}
				this.myChartSamplingClick = true;
			});
		},
		// 下载Excel
		downloadExcel(val) {
			let data = {
				ids: this.pointIds.toString(),
				type: this.dataType
			};
			if (val == 'max') {
				data['max'] = 1;
			}

			if (this.time.begin == '' || this.time.end == '') {
				this.$message({
					showClose: true,
					message: '请先选择时间！',
					type: 'warning'
				});
				return;
			}

			data.begin = this.time.begin;
			data.end = this.time.end;
			this.axios.post('web2/data/odnr/downes', data, { responseType: 'arraybuffer' }).then((res) => {
				const data = res;
				var headersName = res.headers['content-disposition'];
				// // console.log(headersName);

				if (res.byteLength == 0) {
					this.$message({
						message: '暂无Excel数据！',
						type: 'warning'
					});
					return;
				}

				if (res.headers['content-type'].split(';')[0] != 'application/json') {
					let fileName = '';
					if (headersName != undefined) {
						fileName = decodeURIComponent(escape(headersName.split('=')[1]));
					}
					// data 是返回来的二进制数据
					var blob = new Blob([data], {
						type: 'application/vnd.ms-excel;charset=utf-8'
					});
					var href = window.URL.createObjectURL(blob);
					const link = document.createElement('a');
					link.style.display = 'none';
					link.href = href;
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				} else {
					// arraybuffer转字符串
					let resBlob = new Blob([res]);
					let reader = new FileReader();
					reader.readAsText(resBlob, 'utf-8');
					reader.onload = () => {
						// // console.log(reader.result)
						var type = typeof JSON.parse(reader.result);
						if (type == 'string') {
							this.$message({
								message: reader.result,
								type: 'error'
							});
						} else if (type == 'object') {
							let res = JSON.parse(reader.result);
							this.$message({
								message: res.message,
								type: 'error'
							});
						}
					};
				}
			});
		},
		// 绘制图一
		drawChart() {
			if (!this.myChart) {
				var chartDom = document.getElementById('graph');
				var myChart = this.echarts.init(chartDom);
				this.myChart = myChart;
			}

			var option;

			option = {
				grid: {
					left: '72',
					// right: '260',
					top: '10',
					right: '50',
					bottom: '10',
					containLabel: true
				},
				legend: {
					show: false,
					type: 'scroll',
					orient: 'vertical',
					right: 20,
					top: 20,
					bottom: 20,
					textStyle: {
						width: 180,
						overflow: 'breakAll'
					}
				},
				dataZoom: [
					{
						type: 'inside',
						realtime: true,
						start: 0,
						end: 100,
						minValueSpan: this.calculateMaxZoom(),
						moveOnMouseWheel: false
					}
				],
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						animation: false,
						label: {
							backgroundColor: '#505765',
							precision: 4
						}
					},
					formatter: (val) => {
						// console.log(val);
						let text = "<span style='font-size: 17px;font-weight: 600;'>" + val[0].name + '</span><br/>';
						val.forEach((item) => {
							text =
								text +
								`${item.marker + this.tooltipData[item.dataIndex]['name']}&nbsp;最大幅值：${item.data.value}${this.unit}&nbsp;&nbsp;主频：${
									this.tooltipData[item.dataIndex]['frq']
								}Hz<br/>`;
						});
						return text;
					},
					confine: true // 限制tootip在容器内
				},
				xAxis: [
					{
						type: 'category',
						data: [],
						axisTick: {
							alignWithLabel: true
						}
					}
				],
				yAxis: [
					{
						type: 'value',
						axisLine: {
							show: true
						},
						axisTick: {
							show: false
						},
						splitLine: {
							show: false
						},
						axisLabel: {
							show: false,
							formatter: (val) => {
								return Number(val).toFixed(4);
							}
						},
						min: 0
					}
				],
				series: [
					{
						data: [],
						type: 'bar',
						barMaxWidth: '40',
						markLine: {
							data: [],
							silent: true
						}
					}
				]
			};
			var time = [];
			let xData = [];
			let numArr = [];
			let tvArr = [];
			let tvMinArr = [];
			let tvMax = 0;
			let tvMin = 0;
			let alertArr = [];
			for (let k in this.list) {
				this.list[k].forEach((i, index) => {
					if (!time.includes(i.hour)) {
						time.push(i.hour);
					}
					numArr.push(i.val);
				});
			}

			for (let k in this.tagLineData) {
				let el = this.tagLineData[k];
				if (this.pointSelect == 'all') {
					numArr.push(el.tv);
					tvArr.push(el.tv);
					alertArr.push(el.alert);
					if (el.tv) {
						tvMinArr.push(el.tv);
					}
				} else {
					if (el.id == this.pointSelect) {
						numArr.push(el.tv);
						tvArr.push(el.tv);
						alertArr.push(el.alert);
						if (el.tv) {
							tvMinArr.push(el.tv);
						}
						break;
					}
				}
			}
			this.alert = Math.max.apply(null, alertArr);
			if (this.alertShow) {
				numArr.push(this.alert);
			}
			// // console.log(numArr)
			// this.oneYMax = (Math.max.apply(null, numArr) + 0.1).toFixed(2);

			tvMax = Math.max.apply(null, tvArr) ? Math.max.apply(null, tvArr) : 0.5;
			tvMin = Math.min.apply(null, tvMinArr);
			this.oneYMax = tvMax / 0.66;
			option.yAxis[0].max = this.oneYMax;

			time.sort((a, b) => {
				let t1 = new Date(a).getTime();
				let t2 = new Date(b).getTime();
				return t1 - t2;
			});
			let minTime = new Date(time[0]).getTime();
			let maxTime = new Date(time[time.length - 1]).getTime();
			let hoursNum = (maxTime - minTime) / (3600 * 1000 * 24);
			for (let i = 0; i <= hoursNum; i++) {
				xData.push(new Date(minTime + 3600 * 1000 * 24 * i).format('yyyy-MM-dd'));
			}
			// // console.log(xData);

			option.series[0].markLine.data.push({
				type: 'average',
				yAxis: tvMin,
				lineStyle: {
					color: '#008d00',
					type: 'dashed'
				},
				label: {
					show: true,
					position: 'start',
					textStyle: {
						color: 'inherit', //标注线起点文字颜色
						fontSize: 12,
						fontWeight: 500
						// padding: [0, 0, 10, 0] //文字间距
					},
					formatter: (val) => {
						return '触发值\n' + val.value.toFixed(4);
					}
				}
			});

			let allData = [];
			let dataInfoList = [];
			let seriesData = [];
			xData.forEach((i, index) => {
				allData[index] = [];
				dataInfoList[index] = [];
				let hourShow = true;
				for (let k in this.list) {
					try {
						this.list[k].forEach((el) => {
							console.log(el);
							if (el.hour == i) {
								allData[index].push(el.val);
								dataInfoList[index].push({
									...el,
									name: k
								});
								hourShow = false;
								throw '';
							}
						});
					} catch (e) {
						//TODO handle the exception
					}
					if (hourShow) {
						allData[index].push(0);
						dataInfoList[index].push({
							frq: 0,
							hour: i,
							val: 0,
							name: k
						});
					}
				}
			});

			// 计算监测天数
			this.monitoringBriefing.dayNum = xData.length;
			this.monitoringBriefing.exceed = 0;
			this.monitoringBriefing.alertNum = 0;
			this.monitoringBriefing.trigger = 0;
			this.monitoringBriefing.noTrigger = 0;
			this.tooltipData = {};
			allData.forEach((i, index) => {
				let seriesDataMax = Math.max.apply(null, i);
				if (seriesDataMax >= this.alert) {
					this.monitoringBriefing.exceed += 1;
					this.monitoringBriefing.alertNum += 1;
				} else if (seriesDataMax >= tvMin) {
					// console.log('有触发触发');
					this.monitoringBriefing.trigger += 1;
				} else {
					// console.log('无触发');
					this.monitoringBriefing.noTrigger += 1;
				}
				seriesData.push({
					value: seriesDataMax,
					itemStyle: {
						color: seriesDataMax >= this.alert ? this.myChartLegend[2].color : seriesDataMax >= tvMin ? this.myChartLegend[1].color : this.myChartLegend[0].color
					}
				});
				let maxIndex = i.findIndex((item) => {
					if (item == seriesDataMax) {
						return true;
					}
				});
				this.tooltipData[index] = dataInfoList[index][maxIndex];
			});
			option.series[0].data = seriesData;

			if (this.alertShow) {
				let obj1 = {
					data: [],
					type: 'line',
					smooth: true,
					symbol: 'none',
					lineStyle: {
						width: 1
					},
					markLine: {
						data: [],
						silent: true
					}
				};
				obj1.markLine.data.push({
					type: 'average',
					yAxis: this.alert,
					lineStyle: {
						color: '#ff0000',
						type: 'solid'
					},
					label: {
						position: 'start',
						// color: '#ff0000',
						textStyle: {
							color: '#ff0000', //标注线终点文字颜色
							fontSize: 14,
							fontWeight: 500
							// padding: [0, 0, 10, 0] //文字间距
						},
						formatter: (val) => {
							return val.value.toFixed(1);
						}
					}
				});
				option.series.unshift(obj1);
			}
			option.xAxis[0].data = xData;

			option && this.myChart.setOption(option, true);

			this.myChart.off('click');
			this.myChart.getZr().on('click', (params) => {
				if (!this.myChartClick) {
					return;
				}
				this.myChartClick = false;

				const pointInPixel = [params.offsetX, params.offsetY];
				// 使用 convertFromPixel方法 转换像素坐标值到逻辑坐标系上的点。获取点击位置对应的x轴数据的索引值，借助于索引值的获取到其它的信息
				// 转换X轴坐标
				let pointInGrid = this.myChart.convertFromPixel({ seriesIndex: 0 }, pointInPixel);
				// x轴数据的索引值
				// 所点击点的X轴坐标点所在X轴data的下标
				// console.log(pointInGrid);
				let xIndex = pointInGrid[0];
				// 使用getOption() 获取图表的option
				let op = myChart.getOption();
				// 点击点的X轴对应坐标的名称
				var time = op.xAxis[0].data[xIndex];
				// 点击点的series -- data对应的值
				var value = op.series[0].data[xIndex];
				if (time) {
					this.twoQuery.time = time;
					this.oneSamplingShow = true;
					setTimeout(() => {
						if (this.myChart) {
							this.myChart.resize();
						}
						this.twoButShow = true;
						this.getDataTwo(time);
					}, 300);
				}
			});
		},
		// 绘制图二
		drawSampling(val) {
			if (!this.myChartSampling) {
				var chartDom = document.getElementById('sampling');
				var myChart = this.echarts.init(chartDom);
				this.myChartSampling = myChart;
			}
			var option;

			option = {
				grid: {
					top: '35',
					left: '40',
					right: '50',
					bottom: '10',
					containLabel: true
				},
				legend: {
					show: false,
					type: 'scroll',
					orient: 'vertical',
					right: 20,
					top: 20,
					bottom: 20,
					textStyle: {
						width: 180,
						overflow: 'breakAll'
					},
					selected: {}
				},
				dataZoom: {
					type: 'inside',
					// realtime: true,
					start: 0,
					end: 100,
					minValueSpan: this.calculateMaxZoomTwo(),
					moveOnMouseWheel: false
					// moveOnMouseMove: false
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						animation: false,
						label: {
							backgroundColor: '#505765',
							precision: 4
						}
					},
					formatter: (val) => {
						// // console.log(val);
						let text = "<p style='width: 100%; flex-shrink: 0;font-size: 17px;font-weight: 600;'>" + val[0].name + '</p>';
						val.forEach((item) => {
							text =
								text +
								"<div style='width: calc(50% - 5px);'>" +
								`${item.marker + item.seriesName}&nbsp;最大幅值：${item.data <= 0 ? '-' : item.data + this.unit}&nbsp;&nbsp;主频：${
									item.data <= 0 ? '-' : this.twoTooltipData[item.seriesName][item.dataIndex]['frq'] + 'Hz'
								}</div>`;
						});
						return "<div style='width: 720px;display: flex;flex-wrap: wrap;'>" + text + '</div>';
					},
					confine: true // 限制tootip在容器内
				},
				xAxis: {
					type: 'category',
					data: []
				},
				yAxis: [
					{
						name: this.twoQuery.time,
						nameLocation: 'end',
						nameTextStyle: {
							fontSize: 16,
							fontWeight: 600
						},
						type: 'value',
						splitLine: {
							show: false
						},
						axisLine: {
							show: true
						},
						axisLabel: {
							show: true,
							// margin: 12, //刻度标签与轴线之间的距离。
							formatter: (val) => {
								return Number(val).toFixed(4);
							}
						},
						axisTick: {
							show: true
						},
						min: 0
						// max:  this.alertShow ? this.twoYMax : 'dataMax'
					}
				],
				series: []
			};
			var time = [];
			if (JSON.stringify(this.twoList) != '{}') {
				option.series = [];
			}

			let numArr = [];
			let alertArr = [];
			const oneOption = this.myChart.getOption(); //获取图一的图表配置项数据
			this.twoList.forEach((item, index) => {
				let obj = {
					name: '',
					data: [],
					type: 'line',
					symbolSize: 10,
					symbol: (value, params) => {
						// // console.log(params)
						if (value < 1) {
							return 'none';
						} else {
							return 'emptyCircle';
						}
					},
					showSymbol: true,
					smooth: true,
					lineStyle: {
						width: 1
					},
					markLine: {
						data: [],
						silent: true
						// symbol: ['circle', 'none']
					}
				};
				for (let key in this.tagLineData) {
					if (key == item.id) {
						obj.markLine.data.push({
							type: 'average',
							yAxis: this.tagLineData[key].tv,
							lineStyle: {
								type: 'dashed'
							},
							label: {
								show: false,
								position: 'insideStartTop',
								textStyle: {
									color: 'inherit', //标注线起点文字颜色
									fontSize: 12,
									fontWeight: 500
									// padding: [0, 0, 10, 0] //文字间距
								},
								formatter: (val) => {
									return '触发值\n' + val.value.toFixed(1);
								}
							}
						});
						break;
					}
				}

				obj.name = this.pointInfo[item.id];
				obj.color = this.colorArr[obj.name];
				this.twoTooltipData[obj.name] = [];
				item.array.forEach((el) => {
					obj.data.push(el.val);
					numArr.push(el.val);
					this.twoTooltipData[obj.name].push(el);
					if (index == 0) {
						time.push(el.minute);
					}
				});
				option.series.push(obj);
			});

			if (this.alertShow) {
				numArr.push(this.alert);
			}
			for (let k in this.tagLineData) {
				let el = this.tagLineData[k];
				if (this.pointSelect == 'all') {
					alertArr.push(el.alert);
					numArr.push(el.tv);
				} else {
					if (el.id == this.pointSelect) {
						alertArr.push(el.alert);
						numArr.push(el.tv);
						break;
					}
				}
			}
			this.alert = Math.max.apply(null, alertArr);
			this.twoYMax = Math.max.apply(null, numArr) * 1.2;

			option.yAxis[0].max = this.twoYMax;

			if (this.alertShow) {
				let obj1 = {
					// name: '幅值（cm/s）',
					data: [],
					type: 'line',
					smooth: true,
					symbolSize: 6,
					lineStyle: {
						width: 1
					},
					markLine: {
						data: [],
						silent: true
					}
				};
				obj1.markLine.data.push({
					type: 'average',
					yAxis: this.alert,
					lineStyle: {
						color: '#ff0000',
						type: 'solid'
					},
					label: {
						position: 'start',
						// color: '#ff0000',
						textStyle: {
							color: '#ff0000', //标注线终点文字颜色
							fontSize: 14,
							fontWeight: 500
							// padding: [0, 0, 10, 0] //文字间距
						},
						formatter: (val) => {
							return val.value.toFixed(4);
						}
					}
				});
				option.series.unshift(obj1);
			}

			this.myChartSamplingTime = [time[0], time[time.length - 1]];
			option.xAxis.data = time.map(function (str) {
				return str.replace(' ', '\n');
			});
			this.myChartSampling.clear();
			option && this.myChartSampling.setOption(option, true);

			if (this.myChartSampling) {
				this.myChartSampling.resize();
			}

			this.myChartSampling.off('click');
			// this.myChartSampling.on('click', params => {
			// 	if (params.data) {
			// 		let info = {
			// 			id: '',
			// 			minute: params.name.replace('\n', ' ')
			// 		};
			// 		for (let k in this.pointInfo) {
			// 			if (this.pointInfo[k] == params.seriesName) {
			// 				info.id = k;
			// 			}
			// 		}
			// 		this.getBriefingTwo(info);
			// 	}
			// });
			this.myChartSampling.getZr().on('click', (params) => {
				if (!this.myChartSamplingClick) {
					return;
				}
				this.myChartSamplingClick = false;
				const pointInPixel = [params.offsetX, params.offsetY];
				// 使用 convertFromPixel方法 转换像素坐标值到逻辑坐标系上的点。获取点击位置对应的x轴数据的索引值，借助于索引值的获取到其它的信息
				// 转换X轴坐标
				let pointInGrid = this.myChartSampling.convertFromPixel({ seriesIndex: 0 }, pointInPixel);
				// x轴数据的索引值
				// 所点击点的X轴坐标点所在X轴data的下标
				let xIndex = pointInGrid[0];
				// 使用getOption() 获取图表的option
				let op = this.myChartSampling.getOption();
				// 点击点的X轴对应坐标的名称
				var timeText = op.xAxis[0].data[xIndex];
				// 点击点的series -- data对应的值
				// var value = op.series[0].data[xIndex];
				if (params.target == undefined || ('type' in params.target && params.target.type == 'ec-polyline')) {
					// // console.log(timeText);
					if (timeText) {
						let info = {
							id: '',
							minute: ''
						};
						let time = new Date(timeText.replace('\n', ' ') + ':00').getTime();
						let timeArr = [timeText.replace('\n', ' ')];
						let valArr = [];
						let data = [];
						for (let n = 1; n < 10; n++) {
							timeArr.push(new Date(time + n * 60 * 1000).format('yyyy-MM-dd hh:mm'));
							timeArr.push(new Date(time - n * 60 * 1000).format('yyyy-MM-dd hh:mm'));
						}
						timeArr.forEach((t) => {
							if (this.twoTimeList[t]) {
								data.push(...this.twoTimeList[t]);
							}
							data.forEach((i) => {
								valArr.push(i.val);
							});
						});
						let max = Math.max.apply(null, valArr);
						try {
							data.forEach((i) => {
								if (i.val == max) {
									info.id = i.id;
									info.minute = i.minute;
									throw '';
								}
							});
						} catch (e) {
							//TODO handle the exception
						}
						this.getBriefingTwo(info);
					}
				} else if ('type' in params.target && params.target.type == 'path') {
					let time = timeText.replace('\n', ' ');
					let data = [];
					if (this.twoTimeList[time]) {
						data = this.twoTimeList[time];
					}
					let info = {
						id: '',
						minute: time
					};
					let valArr = [];
					data.forEach((i) => {
						valArr.push(i.val);
					});
					let max = Math.max.apply(null, valArr);
					try {
						data.forEach((i) => {
							if (i.val == max) {
								info.id = i.id;
								throw '';
							}
						});
					} catch (e) {
						//TODO handle the exception
					}
					this.getBriefingTwo(info);
				}
			});
		},
		// 图一展开、收起
		openOrStow() {
			this.oneSamplingShow = !this.oneSamplingShow;
			// if (this.myChartSampling) {
			// 	this.myChartSampling.clear();
			// }
			setTimeout(() => {
				// if (this.myChart) {
				// 	this.myChart.resize();
				// }
				if (this.myChartSampling) {
					this.myChartSampling.resize();
				}
				// this.drawSampling();
			}, 10);
		},
		// 图一计算曲线图X轴最小显示数据个数
		calculateMaxZoom() {
			let min = 100;
			let right = 50; //曲线图图形距离右边的像素值
			let left = 72; //曲线图图形距离左边的像素值
			let yWidth = 0; //曲线图y轴占据的宽度(不是真实宽度，自定义的，用于控制点数)
			let width = $('#chartEchartsBox').width();
			yWidth = width * 0.5;
			min = (width - right - left - yWidth) / 6; //除数是指曲线标记的图形宽度。
			return Math.floor(min);
			// return 100
		},
		// 图二计算曲线图X轴最小显示数据个数
		calculateMaxZoomTwo() {
			let min = 100;
			let right = 50; //曲线图图形距离右边的像素值
			let left = 72; //曲线图图形距离左边的像素值
			let yWidth = 0; //曲线图y轴占据的宽度(不是真实宽度，自定义的，用于控制点数)
			let width = $('#chartEchartsBox').width();
			yWidth = -(width * 0.8);
			min = (width - right - left - yWidth) / 10; //除数是指曲线标记的图形宽度。
			// // console.log(min);
			return Math.floor(min);
		},
		// 顶部生成简报按钮点击
		generateBriefing(val) {
			this.briefingType = val;
			let id = val == 1 ? 'graph' : 'sampling';
			if (val == 1) {
				if (!this.oneSamplingShow) {
					this.$message({
						showClose: true,
						message: '请先打开柱状图再尝试生成简报！',
						type: 'warning'
					});
					return;
				}
			} else {
				if (!this.myChartSampling) {
					this.$message({
						showClose: true,
						message: '请先打开点击柱状图数据生成曲线图，再尝试生成日报！',
						type: 'warning'
					});
					return;
				}
			}
			this.getImg('', id, 'url', (imgUrl) => {
				// // console.log(imgUrl);
				let h = $('#' + id).height();
				let w = $('#' + id).width();
				this.proportion = h / w;
				this.briefingImg = imgUrl;
				if (val == 1) {
					this.monitoringBriefing.legend = `<meta charset="UTF-8"><body><p style="text-align: center;">`;
					this.myChartLegend.forEach((i) => {
						this.monitoringBriefing.legend += `<span class="legend-span" style="color: ${i.color};font-size: 18px;">▅</span> <span style="font-size: 12px;margin-right: 10px;">${i.name}  </span>`;
					});
					this.monitoringBriefing.legend += `</p></body>`;
					this.monitoringBriefing.fileName = this.$route.query.name;
					this.monitoringBriefing.startTime = this.time.begin + ' 00:00:00';
					this.monitoringBriefing.endTime = this.time.end + ' 23:59:59';
					this.monitoringBriefing.threshold = this.alert;
					this.monitoringBriefing.pointNum = this.pointSelect == 'all' ? this.pointOption.length : 1;
					this.monitoringBriefing.point = '';

					if (this.pointSelect == 'all') {
						this.pointOption.forEach((i) => {
							this.monitoringBriefing.point += `${i.name}(${i.address})；\t`;
						});
					} else {
						try {
							this.pointOption.forEach((i) => {
								if (i.id == this.pointSelect) {
									this.monitoringBriefing.point += `${i.name}(${i.address})；\t`;
									throw '';
								}
							});
						} catch (e) {
							//TODO handle the exception
						}
					}
					this.dialogBriefing = true;
				} else if (val == 2) {
					this.getBriefingDataTwo();
				}
			});
		},
		// 顶部生成简报预览保存按钮点击
		wordDownClick() {
			this.briefingWordDown();
		},
		// 顶部生成简报下载word
		briefingWordDown() {
			JSZipUtils.getBinaryContent(this.briefingType == 1 ? './monitoringBriefing.docx' : './dailyMonitoringReport.docx', async (error, template) => {
				// 抛出异常
				if (error) {
					throw error;
				}
				let data = {};
				if (this.briefingType == 1) {
					for (let k in this.monitoringBriefing) {
						data[k] = this.monitoringBriefing[k];
					}
				} else if (this.briefingType == 2) {
					for (let k in this.daily) {
						data[k] = this.daily[k];
					}
				}

				const report = await createReport({
					template,
					//这个是你要放在 template.docx模板中的字段，这个变量后面会插入到template.docx模板中
					data: data,
					// 这个对象里面的函数会在合成模板之前被执行
					additionalJsContext: {
						// 返回的格式如下， res 是图片 base64 编码
						getPicture: async () => {
							// // console.log('触发');
							let w = 25;
							let h = w * this.proportion;
							return {
								width: w,
								height: h,
								data: this.briefingImg.replace(/^data:image\/(png|jpg);base64,/, ''),
								extension: '.png'
							};
						}
					},
					// 这个属性定义了模板中变量的边界，例如template.docx中插入变量就可以使用{ creator }
					cmdDelimiter: ['{', '}']
				});
				let fileName = this.briefingType == 1 ? this.time.begin + '到' + this.time.end + '监测简报' : this.time.begin + '监测日报';
				const blob = new Blob([report], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.download = fileName;
				document.body.appendChild(a);
				a.style = 'display: none';
				a.click();
				a.remove();

				setTimeout(() => {
					window.URL.revokeObjectURL(url);
				}, 1000);
			});
		},

		// 图二点击预览数据图形和简报数据
		//列表预览关闭
		handleClose(val) {
			if (val == 'preview') {
				for (let k in this.fileBaseData) {
					this.fileBaseData[k] = '';
				}
			} else if (val == 'generate') {
				for (let k in this.generateInfo) {
					this.generateInfo[k] = '';
				}
			}
		},
		// 关联
		associated() {
			this.$parent.pagechange(this.curveData);
		},
		// 预览弹窗简报按钮点击
		briefingClick() {
			this.dialogPreview = false;
			this.dialogGenerate = true;
		},
		// 图二点击生成简报确定
		generateBriefingDetermine() {
			this.dialogGenerate = false;
			this.dialogBriefing = true;
			setTimeout(() => {
				this.echartsDraw();
			}, 300);
		},
		// 简报曲线图绘制
		echartsDraw() {
			var chartDom1 = document.getElementById('X');
			var myChart1 = this.echarts.init(chartDom1);
			var chartDom2 = document.getElementById('Y');
			var myChart2 = this.echarts.init(chartDom2);
			var chartDom3 = document.getElementById('Z');
			var myChart3 = this.echarts.init(chartDom3);
			var option1;
			var option2;
			var option3;

			option1 = {
				grid: {
					top: 15,
					bottom: 10,
					left: 15,
					right: 15,
					containLabel: true
				},
				xAxis: {
					type: 'category',
					data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
				},
				yAxis: {
					type: 'value'
				},
				series: [
					{
						data: [820, 932, 901, 934, 1290, 1330, 1320],
						type: 'line',
						smooth: true,
						showSymbol: false
					}
				]
			};
			option2 = {
				grid: {
					top: 15,
					bottom: 10,
					left: 15,
					right: 15,
					containLabel: true
				},
				xAxis: {
					type: 'category',
					data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
				},
				yAxis: {
					type: 'value'
				},
				series: [
					{
						data: [820, 932, 901, 934, 1290, 1330, 1320],
						type: 'line',
						smooth: true,
						showSymbol: false
					}
				]
			};
			option3 = {
				grid: {
					top: 15,
					bottom: 10,
					left: 15,
					right: 15,
					containLabel: true
				},
				xAxis: {
					type: 'category',
					data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
				},
				yAxis: {
					type: 'value'
				},
				series: [
					{
						data: [820, 932, 901, 934, 1290, 1330, 1320],
						type: 'line',
						smooth: true,
						showSymbol: false
					}
				]
			};

			option1 && myChart1.setOption(option1);
			option2 && myChart2.setOption(option2);
			option3 && myChart3.setOption(option3);
		}
	},
	components: {},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss">
.chart-box {
	width: 100%;
	height: 100%;
	position: relative;

	.head-box {
		width: calc(100% - 20px);
		padding: 10px 10px 5px 10px;
		display: flex;
		justify-content: flex-start;
		align-items: center;

		.margin-r {
			margin-right: 15px;
		}

		.time-select-box {
			::v-deep {
				.el-date-editor {
					width: 140px;
				}
			}

			.text {
				margin: 0 10px;
			}
		}

		.switch-box {
			display: flex;
			align-items: center;
			font-size: 16px;
			margin-left: 15px;

			.value {
				font-weight: 600;
				color: #d80000;
			}
		}

		.refreshBut {
			padding: 3px 6px;
			margin-left: 15px;

			span {
				font-size: 22px;
				font-weight: 500;
			}
		}
	}

	.annotation-box {
		width: calc(100% - 20px);
		height: 20px;
		padding: 8px 10px 0px 10px;
		display: flex;
		align-items: baseline;
		font-size: 14px;
		color: #909399;

		.n-text {
			font-size: 19px;
			font-family: '微软雅黑';
			font-weight: 600;
			color: #e04040;
		}

		.annotation-title {
			font-size: 15px;
			font-weight: 600;
			color: #898b91;
		}

		.myChart-legend {
			display: flex;
			align-items: center;
			margin-left: 20px;

			.legend-box {
				display: flex;
				align-items: center;
				margin-left: 10px;

				.square {
					width: 22px;
					height: 13px;
					border-radius: 2px;
					margin-right: 5px;
				}
			}
		}
	}

	.echarts-box {
		width: 100%;
		height: calc(100% - 100px);
		// height: 100%;

		#graph {
			width: 100%;
			height: 27%;
			// transition: height 0.5s;
		}

		.big {
			height: calc(100% - 30px) !important;
		}

		.sampling-box {
			width: 100%;
			height: 72%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			::v-deep {
				.open-stow {
					padding: 3px;
					color: #409eff !important;
					background: #ecf5ff !important;
					border-color: #b3d8ff !important;

					i {
						font-size: 20px;
						font-weight: 600;
					}
				}
			}

			#sampling {
				width: 100%;
				height: calc(100% - 30px);
			}
		}

		.small {
			overflow: hidden;
			height: 0px !important;
		}
	}

	.red-legend {
		background: #d80000;
	}

	.green-legend {
		background: #1dcb32;
	}

	.blue-legend {
		background: #1d22d9;
	}

	::v-deep {
		.el-checkbox-group {
			font-size: 12px;
		}
		.el-checkbox__label {
			display: none;
		}

		// 预览
		.preview-dialog {
			width: calc(100% - 40px);
			height: 580px;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto !important;

			.el-dialog__header {
				width: 100%;
				padding: 0;
				background-color: #ffffff;
				color: #000;
				.baseInfo {
					width: 100%;
					height: 80px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					border-bottom: thin solid #dcdfe6;
					.left {
						width: 220px;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 19px;
						font-weight: 600;
					}
					.center {
						width: calc(100% - 460px);
						height: calc(100% - 20px);
						padding: 10px 10px;
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						border-right: thin solid #dcdfe6;
						border-left: thin solid #dcdfe6;

						li {
							width: 33.33%;
							text-overflow: ellipsis;
							white-space: nowrap;
							overflow: hidden;
							font-size: 14px;
						}
					}
					.right {
						width: 220px;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
			}
			.el-dialog__body {
				width: calc(100% - 40px);
				height: calc(100% - 130px);
				padding: 25px 20px;

				.content-box {
					width: 100%;
					height: 100%;

					.legend {
						width: 100%;
						height: 30px;
						display: flex;
						justify-content: center;
						align-items: center;

						.legend-cell {
							display: flex;
							justify-content: flex-start;
							align-items: center;

							.square {
								width: 22px;
								height: 13px;
								border-radius: 4px;
								margin-right: 5px;
							}

							.l-txt {
								margin-left: 5px;
							}
						}

						.margin {
							margin: 0 15px;
						}

						.legend-grey {
							color: #cccccc !important;

							.square {
								background-color: #cccccc !important;
							}
						}
					}

					.echarts-box {
						width: 100%;
						height: 100%;

						.barEcharts {
							width: 100%;
							height: 100%;
							// border-bottom: 2px solid #909399;
						}
					}
				}
			}
		}

		// 生成简报
		.generate-dialog {
			width: 600px;
			height: 370px;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto !important;

			.el-dialog__header {
				padding: 11px 20px;

				.el-dialog__title {
					font-size: 16px;
				}

				.el-dialog__headerbtn {
					top: 8px;
				}
			}

			.el-dialog__body {
				padding: 25px 23px 0px;

				.content-box {
				}
			}
		}
		// 简报预览
		.briefing-dialog {
			width: 880px;
			height: calc(100% - 30px);
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto !important;
			background: none;
			box-shadow: none;

			.el-dialog__header {
				display: none;
			}

			.el-dialog__body {
				width: 100%;
				height: 100%;
				padding: 0 !important;

				.content-box {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: space-between;
					align-items: flex-start;

					.left-box {
						width: calc(100% - 150px);
						height: calc(100% - 40px);
						padding: 20px 30px 10px 30px;
						background-color: #fff;

						.text-table {
							width: 100%;

							tr {
								width: calc(100% - 2px);
								border-right: 1px solid #000;
								border-bottom: 1px solid #000;
								display: table;

								td {
									display: inline-block;
									border-left: 1px solid #000;
									padding: 0 10px;
									color: #000;
								}

								.title {
									width: 100%;
									height: 45px;
									line-height: 45px;
									font-size: 17px;
									font-weight: 600;
									text-align: center;

									.title-color {
										color: #4f88ba;
									}
								}

								.name {
									width: calc(12% - 22px);
									height: 32px;
									line-height: 32px;
									text-align: center;
									font-size: 15px;
									font-weight: 600;
								}

								.data {
									width: calc(38% - 22px);
									height: 32px;
									line-height: 32px;
									text-align: center;
									font-size: 15px;

									.red-text {
										color: #d80000;
									}
								}

								.text-name {
									width: calc(12% - 22px);
									height: 50px;
									line-height: 50px;
									text-align: center;
									font-size: 15px;
									font-weight: 600;
								}

								.text {
									width: calc(88% - 22px);
									height: 50px;
									font-size: 15px;
								}

								.img-text {
									width: calc(100% - 22px);
									min-height: 200px;
									display: flex;
									flex-direction: column;
									align-items: center;
									justify-content: space-around;

									.legend {
										margin-bottom: 15px;

										.legend-span {
											display: inline-block;
											font-family: initial !important;
											height: 18px;
											transform: rotate(90deg);
											margin: 0 2px;
											transform-origin: 50% 80%;
										}

										.name-t {
											font-size: 14px !important;
										}
									}

									img {
										width: calc(100% - 30px);
										height: auto;
										object-fit: scale-down;
									}
								}

								.signature-box {
									width: calc(100% - 22px);
									height: 80px;
									padding: 10px 10px;
									display: flex;
								}
							}

							tr:first-child {
								border-top: 1px solid #000;
							}
						}
					}

					.right-box {
						.but-box {
							height: 100%;
							display: flex;
							flex-direction: column;
							align-items: center;

							p {
								width: 70px;
								height: 60px;
								background-color: #fff;
								border-radius: 3px;
								margin-bottom: 20px;
								text-align: center;
								overflow: hidden;
								cursor: pointer;

								i {
									color: #3b9eff;
									font-size: 20px;
									margin: 8px 0;
									display: block;
								}

								.close {
									font-size: 22px;
									font-weight: 600;
									color: #d80000;
								}

								span {
									display: block;
								}
							}
						}
					}
				}
			}
		}
	}
}
</style>
