export default () => {
	return {
		tree: [],
		placeholder:['企业','项目','测点'],// 条件选择框placeholder文字
		isIndeterminate: false, //是否出现非全选样式
		checkAll: false, //是否全选
		changeSize: 30,
		checkedDevice: [],//多选框选中数据的id
		checkedList: [],//搜索多选框列表数据
		checkedPoint: null,//搜索多选框选中数据的pid
		dataSize: 0,
		dataPoint: 0,
		enterprise: [],
		project: [],
		stationLine: [],
		station: [],
		deviceType: [],
		batchParse: [],
		selectRelevanceData: {},
		item: {
			rows: [],
			page: 1,
			size: 30,
			allPage: 0,
			total: 0,
			time: false,
			type: "historySta",
			dataType: "list",
			text: "暂时没有数据哦--",
		},
		form: {
			timeS: null,
			timeE: null,
		},
		relationId: null,// 点击关联的数据id
		showRelevance:false,// 关联测点页面是否显示
		activeId: null,// 操作行数据id
		parameter: {},//参数
		that: null
	}
}
