<template>
	<div class="historyStaPage pageContainer">
		<!-- <div class="pageMenu">
			<ul class="nav no-select-text">
				<li class="selected">测点数据</li>
				<li v-show="showRelevance" @click="switchNavMenu('HistoryExp')">关联数据</li>
			</ul>
		</div> -->

		<div class="tableHeader no-select-text">
			<span style="width: 5%;"><el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="selectAllDevice">全选</el-checkbox></span>
			<span style="width: 5%;">序号</span>
			<span style="width: 10%;">测点名称</span>
			<span style="width: 10%;">名称/位置</span>
			<span style="width: 12%;">文件名</span>
			<span style="width: 12%;">
				<em>幅值（X）</em>
				<em>主频（X）</em>
			</span>
			<span style="width: 12%;">
				<em>幅值（Y）</em>
				<em>主频（Y）</em>
			</span>
			<span style="width: 12%;">
				<em>幅值（Z）</em>
				<em>主频（Z）</em>
			</span>
			<span style="width: 6%;">预警值</span>
			<span style="width: 6%;text-align: center;">预警报警</span>
			<span style="width: 10%;text-align: center;text-indent: 0">编辑</span>
		</div>

		<div class="tableContainer">
			<div class="tableContent">
				<el-scrollbar :native="false" :noresize="false" tag="div" v-if="item.total">
					<!-- relationId == p.id ||  -->
					<ul :class="activeId == p.id ? 'tableList activeList' : 'tableList'" v-for="(p, i) in item.rows" :key="i">
						<el-checkbox-group v-model="checkedDevice" @change="selectDevice($event, p)">
							<li class="no-select-text select-checkbox" style="width: 5%;"><el-checkbox :label="p.id"></el-checkbox></li>
							<li style="width: 5%;">{{ getListNumber(i, item) }}</li>
							<li style="width: 10%;" :title="p.pointName">{{ p.pointName }}</li>
							<li style="width: 10%;" :title="p.address">{{ p.address ? p.address : '-' }}</li>
							<li style="width: 12%;" :title="timeHandle(p.fileTime, p.suffix)">
								<span class="blue">{{ timeHandle(p.fileTime, p.suffix) }}</span>
							</li>
							<li style="width: 12%;">
								<em :title="toFixed(p.xmax, 4) + p.unit">{{ toFixed(p.xmax, 4) }}{{ p.zmax !== '-' ? p.unit : '' }}</em>
								<em :title="p.xfqc ? toFixed(p.xfqc, 1) + 'Hz' : '0Hz'">{{ p.xfqc ? toFixed(p.xfqc, 1) + 'Hz' : '0Hz' }}</em>
							</li>
							<li style="width: 12%;">
								<em :title="toFixed(p.ymax, 4) + p.unit">{{ toFixed(p.ymax, 4) }}{{ p.ymax !== '-' ? p.unit : '' }}</em>
								<em :title="p.yfqc ? toFixed(p.yfqc, 1) + 'Hz' : '0Hz'">{{ p.yfqc ? toFixed(p.yfqc, 1) + 'Hz' : '0Hz' }}</em>
							</li>
							<li style="width: 12%;">
								<em :title="toFixed(p.zmax, 4) + p.unit">{{ toFixed(p.zmax, 4) }}{{ p.zmax !== '-' ? p.unit : '' }}</em>
								<em :title="p.zfqc ? toFixed(p.zfqc, 1) + 'Hz' : '0Hz'">{{ p.zfqc ? toFixed(p.zfqc, 1) + 'Hz' : '0Hz' }}</em>
							</li>
							<li style="width: 6%;" :title="p.alert ? p.alert : '-'">{{ p.alert ? p.alert : '-' }}{{ p.alert !== '-' ? p.unit : '' }}</li>
							<li style="width: 6%;text-align: center;">
								<span v-show="p.over == 0" class="blue">正常</span>
								<span v-show="p.over == 2" class="yellow">预警</span>
								<span v-show="p.over == 1" class="red">报警</span>
							</li>
							<li style="width: 10%;" class="compile">
								<i @click="preview(p)" title="预览数据" class="icon iconfont icon-yulan outline blue"></i>
								<i @click="relevanceData(p, p.pointName)" title="关联数据" class="icon iconfont icon-guanlian green"></i>
								<i @click="removeList(p, i)" title="移出列表" class="iconfont icon-yichu delete red"></i>
							</li>
						</el-checkbox-group>
					</ul>
				</el-scrollbar>
				<noData v-if="!item.total" />
			</div>

			<div class="tableFooter">
				<Button class="downloadTable" type="downloadTable" :button-callback="buttonCallback" text="下载表单" />
				<Button class="downloadData" type="downloadData" :disabled="!judgment('authorityList', 311, that)" :button-callback="buttonCallback" text="下载数据" />
				<Button class="createWord" type="save" :disabled="!judgment('authorityList', 310, that)" :button-callback="buttonCallback" text="保存报告" />
				<Button class="createWord" type="preview" :disabled="!judgment('authorityList', 310, that)" :button-callback="buttonCallback" text="预览报告" />
				<Button class="deleteData" type="deleteData" :disabled="!judgment('authorityList', 309, that)" :button-callback="buttonCallback" text="删除数据" />

				<div class="sizeBox">
					<span>单页显示条数</span>
					<input type="number" v-model="changeSize" autocomplete="off" />
				</div>

				<Pagination :item="item" :current-page-change="currentPageChange"></Pagination>
			</div>
		</div>
	</div>
</template>

<script>
import historySta from './historySta.js';

export default historySta;
</script>

<style scoped lang="scss">
@import './historySta.scss';
</style>
