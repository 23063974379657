<template>
	<div class="flie-page">
		<div class="flie-setup-box">
			<!-- 报告设置 -->
			<div class="set-up Mask-box">
				<div class="pop-ups-content box-center">
					<p class="pop-ups-title">
						<span>设置报告</span>
						<span class="icon iconfont icon-guanbi close pointer" @click="close('set-up')"></span>
					</p>

					<div class="mian-box">
						<vue-scroll>
							<!-- <div class="cell-box">
								<span class="title">报告名称</span>
								<el-input v-model="setUp.name" placeholder="请输入报告名称"></el-input>
							</div> -->
							<div class="cell-box m-b-n">
								<span class="title">工程名称</span>
								<p class="content">{{ setUp.projectName }}</p>
								<!-- <el-input v-model="setUp.projectName" placeholder="请输入工程名称"></el-input> -->
							</div>
							<div class="cell-box m-b-n">
								<span class="title">工程地址</span>
								<p class="content">{{ setUp.projectAddress }}</p>
								<!-- <el-input v-model="setUp.projectAddress" placeholder="请输入工程地址"></el-input> -->
							</div>
							<div class="cell-box">
								<span class="title">监测单位</span>
								<!-- <p class="content">{{ companyName }}</p> -->
								<el-input v-model="setUp.companyName" placeholder="请输入监测单位"></el-input>
							</div>
							<div class="cell-box">
								<span class="title">委托单位</span>
								<el-input v-model="setUp.entUnit" placeholder="请输入委托单位"></el-input>
							</div>
							<div class="cell-box">
								<span class="title">委托内容</span>
								<el-input v-model="setUp.entContent" placeholder="请输入委托内容"></el-input>
							</div>
							<div class="cell-box basis">
								<span class="title">监测依据</span>
								<el-select v-model="setUp.credential" multiple placeholder="请选择监测依据">
									<el-option v-for="item in basisOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
								</el-select>
							</div>
							<div class="cell-box">
								<span class="title">监测项目</span>
								<el-input v-model="setUp.monitorProject" placeholder="请输入监测项目"></el-input>
							</div>
							<div class="cell-box">
								<span class="title">控制指标</span>
								<el-input class="width-s" v-model="setUp.alert" placeholder="请输入控制指标"></el-input>
								<span class="title operation-box title-s">单位</span>
								<el-input class="width-s" v-model="setUp.unit" placeholder="请输入单位"></el-input>
							</div>
							<div class="cell-box">
								<!-- <span class="title">监测仪器</span>
								<p class="content">L20-N型爆破测振仪</p> -->
								<div class="operation-box box-none">
									<span class="operation-name">矢量合成</span>
									<el-tooltip :content="setUp.compose == 1 ? '开启' : '关闭'" placement="top">
										<el-switch v-model="setUp.compose" :active-value="1" :inactive-value="0"></el-switch>
									</el-tooltip>
								</div>
							</div>
							<div class="cell-box">
								<div class="operation-box box-none">
									<span class="operation-name">启用封面</span>
									<el-tooltip :content="setUp.cover == 1 ? '开启' : '关闭'" placement="top">
										<el-switch v-model="setUp.cover" :active-value="1" :inactive-value="0"></el-switch>
									</el-tooltip>
								</div>
								<div class="operation-box">
									<span class="operation-name">显示衰减计算方法</span>
									<el-tooltip :content="setUp.attenuation == 2 ? '开启' : '关闭'" placement="top">
										<el-switch v-model="setUp.attenuation" :active-value="2" :inactive-value="0"></el-switch>
									</el-tooltip>
								</div>
								<div class="operation-box">
									<span class="operation-name">显示宏观调查内容及方法</span>
									<el-tooltip :content="setUp.macroscopic == 4 ? '开启' : '关闭'" placement="top">
										<el-switch v-model="setUp.macroscopic" :active-value="4" :inactive-value="0"></el-switch>
									</el-tooltip>
								</div>
							</div>
							<div class="cell-box">
								<span class="title">备注</span>
								<el-input type="textarea" :rows="4" placeholder="请输入备注" v-model="setUp.advice"></el-input>
							</div>
						</vue-scroll>
						<div class="but-box">
							<el-button type="primary" size="small" @click="reportSetup">确定</el-button>
							<!-- <el-button type="primary" size="small" @click="saveFlie">保存</el-button>
								<el-button type="primary" size="small" @click="preview">预览</el-button> -->
							<el-button type="info" size="small" @click="close('set-up')">取消</el-button>
						</div>
					</div>
				</div>
			</div>
			<!-- 报告预览 -->
			<div class="preview-box Mask-box" v-if="previewStatus">
				<div class="pop-ups-content box-center">
					<div class="mian-box">
						<div class="left-box"><iframe frameborder="0" scrolling="auto" :src="src" width="1120px" height="100%" /></div>
						<div class="right-box">
							<el-button size="small" @click="downloadPDF">
								<div class="but-content">
									<span class="icon iconfont icon-daochu"></span>
									<span>导出PDF</span>
								</div>
							</el-button>
							<el-button size="small" @click="downloadWord">
								<div class="but-content">
									<span class="icon iconfont icon-daochu"></span>
									<span>导出word</span>
								</div>
							</el-button>
							<el-button v-show="saveShow" size="small" @click="saveFlie(PSData)">
								<div class="but-content">
									<span class="icon iconfont icon-baocun-tianchong"></span>
									<span>保存</span>
								</div>
							</el-button>
							<!-- <el-button size="small" @click="previewStatus = false">
								<div class="but-content">
									<span class="icon iconfont iconffanhui-"></span>
									<span>返回</span>
								</div>
							</el-button> -->
							<el-button size="small" @click="cancel">
								<div class="but-content">
									<span class="icon iconfont el-icon-close close"></span>
									<span>关闭</span>
								</div>
							</el-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: '',
	props: {
		// 预览地址
		viewUrl: {
			type: String,
			default: 'web2/data/odnr/viewrp'
		},
		// 下载word
		downUrl: {
			type: String,
			default: 'web2/data/odnr/downrp'
		},
		// 保存报告
		saveUrl: {
			type: String,
			default: 'web2/data/dmf/save'
		},
		// 保存报告是否调用外部函数
		jump: {
			type: Boolean,
			default: false
		},
		authorityList: {
			type: Array,
			default: () => {
				return [];
			}
		}
	},
	data() {
		return {
			basisOptions: [
				{
					name: '《爆破振动监测技术规范》（T/CSEB0008-2019）',
					id: '1'
				},
				{
					name: '《爆破安全规程》（GB6722-2014）',
					id: '2'
				},
				{
					name: '《铁路工程爆破振动安全技术规程》（TB10313-2019）',
					id: '3'
				},
				{
					name: '《水电水利工程爆破安全监测规程》（DL/T5333-2021）',
					id: '4'
				},
				{
					name: '《工程爆破安全（检）监测技术规范》（DB51/T1167-2010）',
					id: '5'
				},
				{
					name: '《建筑工程容许振动标准》（GB 50868-2013）',
					id: '6'
				},
				{
					name: '《机械振动与冲击 建筑物的振动 振动测量及其对建筑物影响的评价指南》（GBT 14124-2009）',
					id: '7'
				},
				{
					name: '《爆破振动监测技术规范》(TCSEB 0008-2019)',
					id: '8'
				},
				{
					name: '《建筑抗震设计规范》GB50011-2010',
					id: '9'
				},
				{
					name: '《中国地震烈度表》（GB/T 17742-2008）',
					id: '10'
				},
				{
					name: '《岩土工程勘察规范》(GB50021-2001) (2009 年版)',
					id: '11'
				},
				{
					name: '《工程测量规范》(GB50026-2007)',
					id: '12'
				},
				{
					name: '《铁路工程测量规范》（TB 10101-2009）',
					id: '13'
				},
				{
					name: '《铁路隧道监控量测技术规程》(Q/CR9218-2015)',
					id: '14'
				},
				{
					name: '《铁路安全管理条例》中华人民共和国国务院令第639号',
					id: '15'
				}
			], //监测依据选择列表
			setUp: {
				companyName: '', // 公司名称
				// name: '', //报告名称
				projectName: '', // 工程名称
				projectAddress: '', // 工程地址
				credential: [], // 监测依据
				monitorProject: '爆破质点振动速度峰值、主振频率', // 监测项目
				alert: '', // 控制指标
				entUnit: '', // 委托单位
				entContent: '', // 委托内容
				// monitoringInstrument: '', // 监测仪器
				cover: 0, // 是否启用封面
				attenuation: 0, // 显示衰减计算方法
				macroscopic: 0, // 显示宏观调查内容及方法
				compose: 0, // 是否矢量合成
				unit: 'cm/s', //单位
				advice: '' // 建议
				// filesId: '', // 文件id
				// fileId: '', //上级文件id（关联数据列表）
				// pointsId: '' // 测点id（关联数据列表）
			},
			companyName: '', // 公司名称
			ids: [], //传递过来的选中的文件id
			fileInfo: {},
			fileName: '',
			previewStatus: false, // 是否预览报告
			src: '', //报告预览地址
			parameter: {}, // 参数
			PSData: {}, //保存、预览报告参数
			saveShow: true, // 保存报告按钮是否显示
			ops: {
				bar: {
					onlyShowBarOnScroll: false, //不止只在滚动时显示 bar。
					keepShow: true //滚动条一直显示
				}
			} //滚动条设置
		};
	},
	created() {},
	mounted() {},
	computed: {},
	methods: {
		// 预览
		preview(val) {
			this.PSData = val;
			// // console.log(val);
			this.previewStatus = false;
			this.axios.post(this.viewUrl, val, { responseType: 'arraybuffer' }).then(res => {
				// console.log(res.byteLength);
				const data = res;
				var headersName = res.headers['content-disposition'];
				// // console.log(headersName);
				
				if(res.byteLength == 0){
					this.$message({
						message: '暂无pdf数据！',
						type: 'warning'
					});
					return
				}
				
				if (res.headers['content-type'].split(';')[0] != 'application/json') {
					if (headersName != undefined) {
						this.fileName = decodeURIComponent(escape(headersName.split('=')[1]));
					}
					// data 是返回来的二进制数据
					var blob = new Blob([data], {
						type: 'application/pdf;charset=utf-8'
					});
					var href = window.URL.createObjectURL(blob);
					this.src = href;
					this.previewStatus = true;
					$('.flie-page').css({
						zIndex: '1000'
					});
				} else {
					// arraybuffer转字符串
					let resBlob = new Blob([res]);
					let reader = new FileReader();
					reader.readAsText(resBlob, 'utf-8');
					reader.onload = () => {
						// // console.log(reader.result)
						var type = typeof JSON.parse(reader.result);
						if (type == 'string') {
							this.$message({
								message: reader.result,
								type: 'error'
							});
						} else if (type == 'object') {
							let res = JSON.parse(reader.result);
							this.$message({
								message: res.message,
								type: 'error'
							});
						}
					};
				}
			});
		},
		// 下载PDF
		downloadPDF() {
			const link = document.createElement('a');
			link.style.display = 'none';
			link.href = this.src;
			link.setAttribute('download', this.fileName);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},
		// 下载Word
		downloadWord() {
			var val = {
				name: this.fileName
			};
			this.axios.post(this.downUrl, val, { responseType: 'arraybuffer' }).then(res => {
				// // console.log(res);
				const data = res;
				var headersName = res.headers['content-disposition'];
				var fileName = '';
				// // console.log(headersName);
				if(res.byteLength == 0){
					this.$message({
						message: '暂无pdf数据！',
						type: 'warning'
					});
					return
				}

				if (res.headers['content-type'].split(';')[0] != 'application/json') {
					if (headersName != undefined) {
						fileName = decodeURIComponent(escape(headersName.split('=')[1]));
					}
					// data 是返回来的二进制数据
					var blob = new Blob([data]);
					var href = window.URL.createObjectURL(blob);
					const link = document.createElement('a');
					link.style.display = 'none';
					link.href = href;
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				} else {
					// arraybuffer转字符串
					let resBlob = new Blob([res]);
					let reader = new FileReader();
					reader.readAsText(resBlob, 'utf-8');
					reader.onload = () => {
						var type = typeof JSON.parse(reader.result);
						if (type == 'string') {
							this.$message({
								message: reader.result,
								type: 'error'
							});
						} else if (type == 'object') {
							let res = JSON.parse(reader.result);
							this.$message({
								message: res.message,
								type: 'error'
							});
						}
					};
				}
			});
		},
		// 保存
		saveFlie(val) {
			// // console.log(val);
			if (!this.jump) {
				this.axios.post(this.saveUrl, val).then(res => {
					// // console.log(res);
					if (res.status) {
						this.$message({
							message: '保存成功',
							type: 'success'
						});
					}
				});
			} else {
				this.$emit('save');
			}
		},
		// 报告设置
		reportSetup() {
			// // console.log(222)
			for (let key in this.setUp) {
				let item = this.setUp[key];
				if (key != 'credential') {
					if (item != '') {
						if (key != 'cover' && key != 'attenuation' && key != 'macroscopic') {
							this.parameter[key] = item;
						}
					}
				} else {
					this.parameter[key] = item.toString();
				}
			}

			this.parameter['cover'] = this.setUp['cover'] + (this.setUp['attenuation'] + this.setUp['macroscopic']);
			this.axios.post('web2/mang/dmt/mrcf', this.parameter).then(res => {
				if (res.status) {
					this.$message({
						message: '设置成功',
						type: 'success'
					});
					this.close('set-up');
				}
			});
		},
		// 关闭预览
		cancel() {
			this.previewStatus = false;
			this.close('', true);
		},
		// 打开弹窗
		open(data, obj) {
			// console.log(data);
			if (data == null || data == '') {
				this.$message({
					message: '后台数据异常！请联系管理员处理',
					type: 'error'
				});
			}
			this.parameter = obj;
			this.companyName = data.cname;
			this.setUp.companyName = data.cname;
			this.setUp.unit = data.unit;
			if (data.cover < 2){
				this.setUp['cover'] = data.cover;
				this.setUp['attenuation'] = 0;
				this.setUp['macroscopic'] = 0;
			} else if (data.cover == 2){
				this.setUp['cover'] = 0;
				this.setUp['attenuation'] = 2;
				this.setUp['macroscopic'] = 0;
			} else if (data.cover == 3){
				this.setUp['cover'] = 1;
				this.setUp['attenuation'] = 2;
				this.setUp['macroscopic'] = 0;
			} else if (data.cover == 4){
				this.setUp['cover'] = 0;
				this.setUp['attenuation'] = 0;
				this.setUp['macroscopic'] = 4;
			} else if (data.cover == 5){
				this.setUp['cover'] = 1;
				this.setUp['attenuation'] = 0;
				this.setUp['macroscopic'] = 4;
			} else if (data.cover == 6){
				this.setUp['cover'] = 0;
				this.setUp['attenuation'] = 2;
				this.setUp['macroscopic'] = 4;
			} else if (data.cover == 7){
				this.setUp['cover'] = 1;
				this.setUp['attenuation'] = 2;
				this.setUp['macroscopic'] = 4;
			}
			 this.setUp['name'] = data['projectName'] + '爆破振动' + new Date().format('yyyy年MM月dd日');
			if (data.hasOwnProperty('fileId')) {
				this.setUp['fileId'] = data.fileId.toString();
			}
			if (data.hasOwnProperty('pointId')) {
				this.setUp['pointsId'] = data.pointId.toString();
			}
			for (let key in this.setUp) {
				if (key != 'credential') {
					if (key != 'cover' && key != 'attenuation' && key != 'macroscopic') {
						if (data[key] !== undefined && data[key] != null) {
							this.setUp[key] = data[key];
						}
					}
				} else {
					if (data[key] != '') {
						this.setUp[key] = data[key].split(',');
					}
				}
			}

			$('.flie-page').css({
				zIndex: '1000'
			});
			$('.set-up').show();
		},
		// 关闭弹窗
		close(val, status = true) {
			if (val != '') {
				$('.' + val).hide();
			}
			if (status) {
				$('.flie-page').css({
					zIndex: '-1'
				});
				this.reduction();
			}
		},
		// 还原数据
		reduction() {
			for (let key in this.setUp) {
				if (key == 'monitoringBasis') {
					this.setUp[key] = [];
				} else if (key == 'monitorProject') {
					this.setUp[key] = '爆破质点振动速度峰值、主振频率';
				} else {
					if (typeof this.setUp[key] == 'string') {
						this.setUp[key] = '';
					} else if (typeof this.setUp[key] == 'number') {
						this.setUp[key] = 0;
					}
				}
			}
		}
	},
	components: {},
	beforeDestroy() {},
	watch: {
		authorityList: {
			handler(newVal, oldVal) {
				if (newVal.length != 0) {
					if (newVal.indexOf(310) != -1) {
						this.saveShow = true;
					} else {
						this.saveShow = false;
					}
				} else {
					this.saveShow = true;
				}
			},
			immediate: true
		}
	}
};
</script>

<style scoped lang="scss">
.flie-page {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;

	.flie-setup-box {
		width: 100%;
		height: 100%;
		position: relative;

		.width-s {
			width: 100px !important;
		}

		// 报告设置
		.set-up {
			z-index: 1000;
			display: none;

			// 弹窗标题
			.pop-ups-title {
				// width: 100%;
				// height: 35px;
				// line-height: 35px;
				// font-size: 14px;
				// text-align: center;
				// background-color: #66b1ff;
				// color: #ffffff;
				// position: relative;

				.close {
					display: inline-block;
					width: 15px;
					height: 15px;
					position: absolute;
					top: 0px;
					bottom: 0;
					margin: auto;
					right: 15px;
					color: #ffffff;
					font-size: 14px;
				}
			}

			.pop-ups-content {
				width: 600px;
				height: 655px;
				background-color: #ffffff;

				.mian-box {
					width: calc(100% - 30px);
					height: calc(100% - 120px);
					padding: 15px;

					.cell-box {
						width: 100%;
						display: flex;
						// flex-direction: column;
						justify-content: flex-start;
						align-items: center;
						margin-bottom: 12px;

						.title {
							width: 70px;
							height: 30px;
							line-height: 30px;
							display: inline-block;
							font-size: 13px;
							// margin-bottom: 5px;

							i {
								color: #f20000;
								font-size: 12px;
							}
						}

						.title-s {
							width: 45px;
						}

						::v-deep {
							.el-input {
								width: calc(100% - 70px);
							}
							.el-select {
								width: calc(100% - 70px);
							}
							.el-select .el-tag {
								max-width: 450px;
								padding-right: 20px;
								height: auto;
								white-space: pre-wrap;
								position: relative;
							}
							.el-tag .el-tag__close {
								position: absolute;
								top: 0;
								right: 0;
								bottom: 0;
								margin: auto;
							}
							.el-select .el-input {
								width: 100%;
							}
							.basis {
								.el-input__inner {
									height: auto !important;
								}
							}
							.el-textarea {
								width: calc(100% - 70px);
							}
							.el-input__inner {
								width: 100%;
								height: 35px;
							}
						}

						.operation-box {
							border-left: thin solid #909399;
							margin-left: 20px;
							padding-left: 10px;
							display: flex;
							align-items: center;

							::v-deep {
								.el-switch__label * {
									font-size: 12px;
								}
							}

							.operation-name {
								font-size: 13px;
								margin-right: 17px;
							}
						}

						.box-none {
							border-left: none;
							margin-left: 0;
							padding-left: 0;
						}

						.content {
							height: 35px;
							line-height: 35px;
							font-size: 15px;
						}
					}

					.m-b-n {
						margin-bottom: 0;
					}

					.but-box {
						width: 196px;
						margin: 12px 0 0 0;
						display: flex;
						justify-content: center;
						align-items: center;
						float: right;
					}
				}
			}
		}
		// 报告预览
		.preview-box {
			// display: none;
			z-index: 10000;

			.pop-ups-content {
				width: 1190px;
				height: 100%;

				.mian-box {
					width: 100%;
					height: 98%;
					display: flex;
					margin-top: 10px;

					.left-box {
						height: 100%;
					}

					.right-box {
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						align-items: center;

						::v-deep {
							.el-button {
								padding: 0;
								width: 56px;
								height: 56px;
								margin-left: 14px;
								margin-bottom: 15px;
							}
						}

						.but-content {
							height: 100%;
							display: flex;
							flex-direction: column;
							justify-content: space-around;
							align-items: center;

							.icon {
								color: #409eff;
								font-size: 22px;
							}
							
							.close{
								font-size: 22px;
								font-weight: 600;
								color: #d80000;
							}

							.iconhoutaitubiao-25 {
								font-size: 35px !important;
							}

							span:last-child {
								color: #000;
							}
						}
					}
				}
			}
		}
	}
}
</style>
