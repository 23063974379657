import data from "./briefReportData.js"
import GitChartImg from "../../../assets/docxtemplater/GitChartImg";

import JSZipUtils from 'jszip-utils';
import {
	createReport
} from 'docx-templates';


export default {
	name: "briefReport",
	data,
	created() {},
	computed: {},
	methods: {
		exportToPDF() {
			//判断iframe是否存在，不存在则创建iframe
			let iframe = document.getElementById("print-iframe");
			let el = document.getElementById("briefReport");
			let doc = null;

			if (!iframe) {
				iframe = document.createElement('IFRAME');
			}

			iframe.setAttribute("id", "print-iframe");
			iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:-500px;top:-500px;');
			document.body.appendChild(iframe);
			doc = iframe.contentWindow.document;
			//这里可以自定义样式
			doc.write('<LINK rel="stylesheet" type="text/css" href="/static/statement.css">');
			doc.write('<div>' + el.innerHTML + '</div>');
			doc.close();
			iframe.contentWindow.focus();

			iframe.contentWindow.print();
			if (navigator.userAgent.indexOf("MSIE") > 0) {
				document.body.removeChild(iframe);
			}
		},
		base64DataURLToArrayBuffer(dataURL) {
			const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
			if (!base64Regex.test(dataURL)) {
				return false;
			}
			const stringBase64 = dataURL.replace(base64Regex, "");
			let binaryString;
			if (typeof window !== "undefined") {
				binaryString = window.atob(stringBase64);
			} else {
				binaryString = new Buffer(stringBase64, "base64").toString("binary");
			}
			const len = binaryString.length;
			const bytes = new Uint8Array(len);
			for (let i = 0; i < len; i++) {
				bytes[i] = binaryString.charCodeAt(i);
			}
			return bytes.buffer;
		},
		// 点击导出word
		exportWord: function() {
			JSZipUtils.getBinaryContent('./briefReport.docx', async (error, template) => {
				// 抛出异常
				if (error) {
					throw error;
				}
				let data = {
					...this.form
				};
				let location = data.location;
				delete data.location;
				delete data.imageX;
				delete data.imageY;
				delete data.imageZ;
				data.addressText = location;
				const report = await createReport({
					template,
					//这个是你要放在 template.docx模板中的字段，这个变量后面会插入到template.docx模板中
					data: data,
					// 这个对象里面的函数会在合成模板之前被执行
					additionalJsContext: {
						// 返回的格式如下， res 是图片 base64 编码
						getPictureX: async () => {
							let w = 12;
							let h = w * this.proportion;
							return {
								width: w,
								height: h,
								data: this.form.imageX.replace(
									/^data:image\/(png|jpg);base64,/, ''),
								extension: '.png'
							};
						},
						getPictureY: async () => {
							let w = 12;
							let h = w * this.proportion;
							return {
								width: w,
								height: h,
								data: this.form.imageY.replace(
									/^data:image\/(png|jpg);base64,/, ''),
								extension: '.png'
							};
						},
						getPictureZ: async () => {
							let w = 12;
							let h = w * this.proportion;
							return {
								width: w,
								height: h,
								data: this.form.imageZ.replace(
									/^data:image\/(png|jpg);base64,/, ''),
								extension: '.png'
							};
						}
					},
					// 这个属性定义了模板中变量的边界，例如template.docx中插入变量就可以使用{ creator }
					cmdDelimiter: ['{', '}']
				});
				let fileName = this.form.name + "简报.docx";
				const blob = new Blob([report], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.download = fileName;
				document.body.appendChild(a);
				a.style = 'display: none';
				a.click();
				a.remove();
				
				setTimeout(() => {
					window.URL.revokeObjectURL(url);
				}, 1000);
			})
		},
		setWordImages(data) {
			// let data = this.randomData();
			new GitChartImg(data, res => {
				this.form.imageX = res[0];
				this.form.imageY = res[1];
				this.form.imageZ = res[2];
			}, {
				merge: false,
				width: 900,
				height: 400
			}, this);
			this.proportion = 400 / 900;
		},
		//隐藏弹窗
		closeLayer() {
			$('.briefReportPage').fadeOut(300)
		},
		//显示弹窗
		showLayer(form) {
			console.log(form);
			for (let i in form) {
				if (i.indexOf('Time') == -1) {
					this.form[i] = form[i] ? form[i] : '-';
				} else {
					this.form[i] = form[i] ? form[i] : '0';
				}
			}
			// console.log(this.form)
			$('.briefReportPage').fadeIn(300);
		},
		//返回
		goBack() {

		}
	},
	mounted() {
		// this.setWordImages();
	},
	beforeDestroy() {},
	watch: {}
}