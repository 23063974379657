export default () => {
  return {
    form: {
      name: '测点编号',
      filename: '2019/03/15 08:35:54',
      project: '爆破振动检测项目',
      location: '隧道迎爆侧侧壁，距离地面1.5米',
      focus: '隧道爆破钻爆施工振动距测点10米',
      enterprise: '四川交博环境检测有限公司',
      operator: '张薇薇',
      deviceName: '爆破测振仪L20-N',
      deviceId: 'L20-N0001',
      delayed: '0.025',
      sampling: '10',
      imageX: '',
      imageY: '',
      imageZ: '',
      peakX: 0.4375, freqX: 108.9362, maxXTime: 0,
      peakY: 1.3695, freqY: 68.2667, maxYTime: 0,
      peakZ: 0.3932, freqZ: 78.7692, maxZTime: 0,
      CVal: 1.5,
      negativeDelay: 1, //负延迟
      sampleRate: 1, //采样率
	  units: 'cm/s'
    },
	proportion: 0,
    count:true
  }
}
