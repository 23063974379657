export default () => {
	return {
		DOM: '',
		tree: [],
		officePath: '',
		changeSize: 30,
		selectItem: [],
		parameter:{},// 参数
		isIndeterminate: false, //是否出现非全选样式
		checkAll: false, //是否全选
		checkedArr: [],//多选框选中时多选框绑定的数据index
		checkedIndex: [],//多选框选中时存储的数据index
		checkedList: [],//多选框数据
		checkedPoint: '',//多选框选中数据的pid
		checkedId:{},//多选框选中数据中标红的一条数据id和测点id
		checkAll: false,
		checkAllStatus: 0,
		dataSize: 0,
		dataPoint: 0,
		number: 0,
		enterprise: [],
		project: [],
		stationLine: [],
		station: [],
		selectDate: {},
		selectStation: 2,
		queryFile: 2,//筛选文件条件数据
		deviceType: [{
				id: 0,
				name: '全部数据'
			},
			{
				id: 1,
				name: '有数据'
			},
			{
				id: 2,
				name: '无数据'
			},
			{
				id: 3,
				name: '测点编号±1'
			},
			{
				id: 4,
				name: '测点编号±2'
			},
			{
				id: 5,
				name: '测点编号±3'
			},
		],
		stationType: [{
				id: 2,
				name: '全部测点'
			},
			{
				id: 1,
				name: '启用测点'
			},
			{
				id: 0,
				name: '停用测点'
			},
		],
		placeholder: '企业',
		item: {
			rows: [],
			page: 1,
			size: 30,
			allPage: 0,
			total: 0,
			type: 'historyExp',
			dataType: 'list',
			text: '暂时没有数据哦--',
			rank: 'number', // 排序依据
			// rank: '', // 排序依据
			type_: 0
		},
		form: {
			organization: null,
			type: 0,
			stationLine: null,
			timeS: null,
			timeE: null,
		},
		beforeName: '',
		beforeType: '',
		showRelevance: false,
		that: null
	}
}
