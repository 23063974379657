import data from "./historyStaData.js";

export default {
	name: "historySta",
	components: {},
	props: {
		// switchNavMenu: {
		// 	type: Function,
		// 	required: true
		// },
		authorityList:{
			type: Array,
			default: ()=>{
				return []
			}
		}
	},
	data,
	created() {
		var parameter = this.$route.query;
		for(let key in parameter){
			this.parameter[key] = parameter[key];
		}
		if (!this.parameter.hasOwnProperty('id')) {
			this.toRouter('index');
		}
		this.that = this;
	},
	mounted() {
		// this.getDeviceFile();
	},
	computed: {},
	methods: {
		//翻页组件触发分页回调
		currentPageChange(type) {
			// // console.log(type)
			if (type === "historySta") {
				if (this.changeSize !== this.item.size) {
					this.item.size = this.changeSize;
					this.item.page = 1;
				}
				this.getDeviceFile();
				// // console.log(123)
			}
		},
		//按钮组件点击事件回调
		buttonCallback(type) {
			if (this.checkedDevice.length === 0 && type !== "search") {
				this.$messages("请选择数据", "error");
				return false;
			}
			if (type === "downloadData") {
				this.downloadFile();
			} else if (type === "downloadTable") {
				this.downLoadExcels();
			} else if (type === "deleteData") {
				this.deleteFileData();
			} else if (type === "search") {
				this.item.page = 1;
				this.item.size = this.changeSize;
				this.item.time = true;
				this.getDeviceFile();
			} else if (type === "save") {
				var data = {
					filesId: this.checkedDevice.toString(),
					pointId: this.checkedPoint,
				}
				this.$parent.buttonCallback(data, type);
			} else if (type === "preview") {
				var data = {
					filesId: this.checkedDevice.toString(),
					pointId: this.checkedPoint,
				}

				this.$parent.buttonCallback(data, type);
			}
		},
		//获取设备文件列表
		getDeviceFile() {
			const {
				page,
				size
			} = this.item;
			const data = {};
			data.page = page;
			data.size = size;
			data.pjid = this.parameter.id;
			if(this.$parent.checkedPoint != 0){
				data.pid = this.$parent.checkedPoint;
			}
			if (this.item.time) {
				data.begin = this.form.timeS;
				data.end = this.form.timeE;

				if (new Date(data.sTime).getTime() > new Date(data.eTime).getTime()) {
					this.$messages("开始时间大于结束时间，请重新选择", "warning", 3000);
					return false;
				}
			}
			// // console.log(this.item.time)
			// // console.log(this.form)
			this.checkedDevice = [];
			this.isIndeterminate = false;
			this.checkAll = false;

			this.axios.post("web2/data/odnr/gpdlist", data).then((res) => {
				// // console.log(res)
				this.item.rows = [];

				if (res.status) {
					this.item.rows = res.data.list;
					this.item.total = res.data.total;
					this.item.allPage = Math.ceil(res.data.total / size);
					let len = res.data.list.length;
					if(page != 1 && len == 0 && res.data.total != 0){
						this.item.page = page - 1;
						this.getDeviceFile();
					}
				}
			})

		},
		//选中设备列表
		selectDevice(e, p) {
			this.isIndeterminate = e.length > 0 && this.item.rows.length > this.checkedDevice.length;
			this.checkAll = this.item.rows.length == this.checkedDevice.length;
			// console.log(e)
			// console.log(this.checkedDevice)
			// console.log(p.id)
			if (e.length == 0) {
				this.checkedDevice = [];
				this.checkedPoint = null;
			} else if (this.checkedDevice.indexOf(p.id) != -1) {
				if (this.checkedPoint == null) {
					this.checkedPoint = p.pid;
				}
			} else {
				var pointIndex = this.checkedPoint.indexOf(p.pid);
				if (pointIndex != -1) {
					this.checkedPoint = null;
				}
			}
		},
		//全选当前页
		selectAllDevice(val) {
			if (val) {
				this.checkedDevice = [];
				this.checkedPoint = null;
				this.item.rows.forEach((item) => {
					this.checkedDevice.push(item.id);
					if (this.checkedPoint == null) {
						this.checkedPoint = item.pid;
					}
				})
			} else {
				this.checkedDevice = [];
				this.checkedPoint = null;
			}
			this.isIndeterminate = false;
		},
		//批量下载源文件并打包
		downloadFile() {
			// // console.log('下载')
			var data = {
				filesId: this.checkedDevice.toString()
			};
			this.axios.post('web2/data/dme/dnbf', data, {
				responseType: 'arraybuffer'
			}).then((res) => {
				// // console.log(res)
				var headersName = res.headers['content-disposition'];
				var fileName = '';
				// // console.log(headersName);

				if (res.headers['content-type'].split(';')[0] != 'application/json') {
					if (headersName != undefined) {
						fileName = decodeURIComponent(escape(headersName.split('=')[1]));
					}
					// data 是返回来的二进制数据
					const data = res;
					const url = window.URL.createObjectURL(new Blob([data]));
					const link = document.createElement('a')
					link.style.display = 'none'
					link.href = url;
					link.setAttribute('download', fileName)
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				} else {
					// arraybuffer转json
					let resBlob = new Blob([res])
					let reader = new FileReader()
					reader.readAsText(resBlob, "utf-8")
					reader.onload = () => {
						var type = typeof JSON.parse(reader.result);
						if(type == 'string'){
							this.$message({
								showClose: true,
								message: reader.result,
								type: 'error'
							});
						}else if(type == 'object'){
							let res = JSON.parse(reader.result);
							this.$message({
								showClose: true,
								message: res.message,
								type: 'error'
							});
						} 
					}
				}
			})

		},
		//下载Excel表单
		downLoadExcels() {
			var data = [
				['序号', '测点编号', '名称位置', '文件名', '幅值(X)', '主频(X)', '幅值(Y)', '主频(Y)', '幅值(Z)', '主频(Z)', '报警值', '是否超标']
			]; //表格标题
			var width = [8, 15, 24, 22, 14, 12, 14, 12, 14, 12, 8, 10]; //表格每列宽度
			var arr = [];
			var timeArr = [];
			var index = 0;
			this.item.rows.forEach((item) => {
				if (this.checkedDevice.indexOf(item.id) != -1) {
					index += 1;
					timeArr.push(item.fileTime);
					var flieName = item.fileTime ? (item.fileTime + item.suffix) : '-';
					var maxX = item.xmax ? (item.xmax.toFixed(4) + item.unit) : '-';
					var Xzp = item.xfqc ? (item.xfqc.toFixed(1) + 'Hz') : '-'
					var maxY = item.ymax ? (item.ymax.toFixed(4) + item.unit) : '-';
					var Yzp = item.yfqc ? (item.yfqc.toFixed(1) + 'Hz') : '-';
					var maxZ = item.zmax ? (item.zmax.toFixed(4) + item.unit) : '-';
					var Zzp = item.zfqc ? (item.zfqc.toFixed(1) + 'Hz') : '-';
					var obj = {
						id: index,
						index: index.toString(),
						pointName: item.pointName ? item.pointName : '-',
						address: item.address ? item.address : '-',
						flieName: flieName,
						maxX: maxX,
						Xzp: Xzp,
						maxY: maxY,
						Yzp: Yzp,
						maxZ: maxZ,
						Zzp: Zzp,
						alert: item.alert ? (item.alert + item.unit) : '-',
						over: item.over ? '是' : '否',
					};
					arr.push(obj);
				}
			})

			data.push(arr);
			var max = this.dismantling(Math.max(...timeArr));
			var min = this.dismantling(Math.min(...timeArr));
			var time = min + '—' + max;
			// // console.log(data)
			// // console.log(time)
			// // console.log(width)
			this.downloadExcel(data, time, width);
		},
		//删除设备源文件数据
		deleteFileData() {
			this.$confirm('您确定要永久删除源文件吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.axios
					.post(`web2/data/dmg/dlbn`, {
						filesId: this.checkedDevice.toString()
					})
					.then(res => {
						if (res.status) {
							this.$message({
								showClose: true,
								type: 'success',
								message: '删除成功!'
							});
							this.checkedDevice = [];
							this.checkedPoint = '';
							let {
								total,
								page,
								size
							} = this.item;
							this.item.page = this.calculatePages(
								total,
								page,
								size,
								this.checkedDevice.length
							);
							this.getDeviceFile();
						}
					});
			}).catch(() => {});
		},
		//预览
		preview(item) {
			// // console.log(item)
			this.activeId = item.id;
			item.pageType = 1;
			this.$parent.filePreview(item);
		},
		//联级选择回调
		changeValue(val) {
			this.item.page = 1;
			this.item.time = false;
			this.getDeviceFile();
		},
		//删除列表中的数据，假删除
		removeList(item, index) {
			this.item.rows.splice(index, 1);
			var d = this.checkedDevice.indexOf(item.id);
			if(d != -1){
				this.checkedDevice.splice(d, 1);	
			}
			if(this.checkedDevice == 0){
				this.isIndeterminate = false;
				this.checkAll = false;
			}
			this.$forceUpdate();
		},
		// 关联数据
		relevanceData(item, pointname) {
			// // console.log('aaaa')
			// // console.log(item)
			// // console.log(pointname)
			this.relationId = item.id;
			this.activeId = item.id;
			// // console.log(this.relationId)
			this.$parent.pagechange(item);
		},
	},
	beforeDestroy() {

	},
	watch: {}
};
