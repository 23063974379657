<template>
  <div class="briefReportLayer">
    <div class="basics infoItem">
      <div class="infoContent">
        <el-scrollbar :native="false" :noresize="false" tag="div">
          <label class="list">
            <span>项目名称</span>
            <input type="text" v-model="form.project" placeholder="请输入项目名称" autocomplete="off">
          </label>
          <label class="list">
            <span>企业名称</span>
            <input type="text" v-model="form.enterprise" placeholder="请输入企业名称" autocomplete="off">
          </label>
          <label class="list">
            <span>测点编号</span>
            <input type="text" v-model="form.name" placeholder="请输入测点编号" autocomplete="off">
          </label>
          <label class="list">
            <span>名称/位置</span>
            <input type="text" v-model="form.location" placeholder="请输入名称/位置" autocomplete="off">
          </label>

          <label class="list">
            <span>爆破参数</span>
            <input type="text" v-model="form.focus" placeholder="详细填写爆破参数、爆源与测点间位置关系" autocomplete="off">
          </label>
<!--          <label class="list">-->
<!--            <span>操作人员</span>-->
<!--            <input type="text" v-model="form.operator" placeholder="请输入操作人员" autocomplete="off">-->
<!--          </label>-->
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>
<script>

  export default {
    name: "briefReportLayer",
    data() {
      return {
        form: {
          project: '爆破振动检测项目',
          name: '测点编号',
          filename: '2019/03/15 08:35:54',
          location: '',
          enterprise: '',
          focus: '',
          operator: '张薇薇',
          deviceName: '爆破测振仪L20-N',
          deviceId: 'L20-N0001',
          sampleRate: 1, //采样率
          CVal: 0,
		  units: 'cm/s'
        }
      }
    },
    computed: {},
    methods: {
      getFormData(){	
        return this.form;
      },
      showLayer(data){
		  // console.log('报表')
        this.form.name = data.pointName;
        this.form.operator = data.operator;
        this.form.sampling = parseInt(data.sampleRate / 1000);
        this.form.enterprise = data.companyName;
        this.form.project = data.projectName;
        // this.form.focus = data.boomInfo;
        this.form.focus = '';
        this.form.peakX = Number(Number(data.maxXData).toFixed(4));
        this.form.peakY = Number(Number(data.maxYData).toFixed(4));
        this.form.peakZ = Number(Number(data.maxZData).toFixed(4));
        this.form.freqX = Number(Number(data.mainFX).toFixed(4));
        this.form.freqY = Number(Number(data.mainFY).toFixed(4));
        this.form.freqZ = Number(Number(data.mainFZ).toFixed(4));
        this.form.maxXTime = Number(Number(data.maxXTime).toFixed(4));
        this.form.maxYTime = Number(Number(data.maxYTime).toFixed(4));
        this.form.maxZTime = Number(Number(data.maxZTime).toFixed(4));
        this.form.filename = new Date(data.fileTimeStamp).format();
        this.form.deviceName = data.deviceName;
        this.form.deviceId = data.deviceNum;
        this.form.location = data.address;
		this.form.units = data.units;
        $('.layerContainerBox').fadeIn(300).find('.briefReport').show().siblings().hide();
      }
    },
  }
</script>

<style scoped lang="scss">
  .briefReportLayer {
    width: 100%;
    height: 100%;
    flex: 1 1 auto;
    overflow: hidden;
    padding: 0 10px 0 20px;
    box-sizing: border-box;
    display: flex;

    .infoItem {
      flex: 1 1 auto;
      display: flex;
      flex-flow: column;
      width: 100%;

      .title {
        flex: 0 0 20px;
        line-height: 20px;
        color: #3B9EFF;
      }

      .infoContent {
        flex: 1 1 auto;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-flow: column;


        .list {
          //width: 290px;
          line-height: 50px;
          float: left;

          span {
            float: left;
            width: 55px;
            text-align: right;
            margin-right: 10px;
            color: #4A4A4A;
          }

          input {
            width: 500px;
            height: 28px;
            padding: 0 10px;
            box-sizing: border-box;
            border: 1px solid #E5E5E5;
            font-size: 12px;
          }
        }

        .list:nth-child(2n) {
          margin-left: 0;
        }

        .list:last-child {
          margin-bottom: 10px;
        }
      }
    }
  }
</style>
