export default () => {
  return {
    form: {
      title: "同爆次统计监测报告",
      project: "南安市柳湖水系连通综合整治工程后桥引水工程爆破振动监测项目", //工程名称
      parameter: "振动幅值、主振频率", // 监测参数
      type: "现场监测", // 监测方法
      regulation: "《爆破安全规程》(GB6722-2014)", // 监测依据
      device: "爆破测振仪L20-N", //监测仪器
      protect: "民房",
      count: 6,
      overproof: 0,
      conclusion: "",
      remarks: "请详细填写爆破位置及爆破参数",
      date: '',
      wordTime: '',
      entrust: '',
      contract: '',
      content: '',
      periods: '',
      background: '',
      cma: '',
      s1: '',
      s2: '',
      s3: '',
      cover: false
    },
    // 表格信息
    tableData: [],
    interval: null,
    percent: 0,
    layerForm: {}
  }
}
