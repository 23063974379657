<template>
	<div class="historyPage">
		<div class="header">
			<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
				<el-menu-item index="1" v-if="judgment('authorityList', 308, that)">抽样数据</el-menu-item>
				<el-menu-item index="2">测点数据</el-menu-item>
				<el-menu-item index="3" v-show="showRelevance">关联数据</el-menu-item>
			</el-menu>
			<!-- 抽样数据 -->
			<div class="right-box chart-screen" v-show="activeIndex == '1'">
				<!-- <el-radio-group v-model="samplingType" size="small" @change="samplingTypeChange">
					<el-radio-button label="1">合成值</el-radio-button>
					<el-radio-button label="2">三向值</el-radio-button>
				</el-radio-group> -->
				<!-- <div class="screen time">
					<span>时段：</span>
					<el-date-picker
						popper-class="chart-time"
						v-model="samplingTime.timeS"
						type="datetime"
						:clearable="false"
						value-format="yyyy-MM-dd HH"
						format="yyyy-MM-dd HH"
						placeholder="选择日期时间"
					></el-date-picker>
					<span>至</span>
					<el-date-picker
						popper-class="chart-time"
						v-model="samplingTime.timeE"
						type="datetime"
						:clearable="false"
						value-format="yyyy-MM-dd HH"
						format="yyyy-MM-dd HH"
						placeholder="选择日期时间"
					></el-date-picker>
				</div> -->
				<!-- <el-button type="primary" size="small" @click="samplingQuery">查询</el-button> -->
				<el-popover class="popover" popper-class="chart-briefing-but" placement="bottom" trigger="click">
					<div class="select-but">
						<el-button type="success" size="mini" @click="generateBriefing(1)">生成简报</el-button>
						<el-button type="success" size="mini" @click="generateBriefing(2)">生成日报</el-button>
					</div>
					<el-button slot="reference" type="success" size="small">生成简报</el-button>
				</el-popover>
				<el-popover class="popover" popper-class="chart-briefing-but" placement="bottom" trigger="click">
					<div class="select-but">
						<el-button type="primary" size="mini" @click="downloadExcel('max')">下载最大值</el-button>
						<el-button type="primary" size="mini" @click="downloadExcel('all')">下载全部数据</el-button>
					</div>
					<el-button slot="reference" type="primary" size="small">下载excel</el-button>
				</el-popover>
				
			</div>
			<!-- 测点数据 -->
			<div class="right-box" v-show="activeIndex == '2'">
				<div class="screen selection">
					<el-select v-model="checkedPoint" filterable placeholder="请选择测点">
						<el-option label="全部" :value="0"></el-option>
						<el-option v-for="(item, index) in checkedList" :key="index" :label="item.name" :value="item.id">
							<!-- :hide-after="300" -->
							<el-tooltip effect="light" :content="item.address" placement="right" :enterable="false">
								<span>{{ item.name }}</span>
							</el-tooltip>
						</el-option>
					</el-select>
				</div>
				<div class="screen time">
					<span>时段：</span>
					<el-date-picker v-model="form.timeS" type="datetime" :clearable="false" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
					<span>至</span>
					<el-date-picker v-model="form.timeE" type="datetime" :clearable="false" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
				</div>
				<el-button type="primary" size="small" @click="theQuery">查询</el-button>
			</div>

			<!-- 关联数据 -->
			<div class="screen" v-show="activeIndex == '3'">
				<div class="screen-cell">
					<span>文件：</span>
					<el-select v-model="queryFile" @change="selectData" placeholder="请选择筛选条件">
						<el-option v-for="item in queryFileList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="screen-cell">
					<span>测点：</span>
					<el-select v-model="selectStation" @change="selectData" placeholder="请选择筛选条件">
						<el-option v-for="item in stationType" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</div>
			</div>
		</div>

		<div class="child-page">
			<!-- 抽样数据 -->
			<sampling ref="sampling" v-show="activeIndex === '1'" :authorityList="authorityList" :dataType="samplingType" :pointOption="checkedList"></sampling>
			<!-- 测点数据 -->
			<HistorySta ref="HistorySta" v-show="activeIndex === '2'" :authorityList="authorityList"></HistorySta>
			<!-- 关联数据 -->
			<HistoryExp ref="HistoryExp" v-show="activeIndex === '3'" :authorityList="authorityList"></HistoryExp>
		</div>

		<!--  简报报表  -->
		<briefReport ref="briefReport" />

		<!--  批量报表  -->
		<statement ref="statement"></statement>

		<!--  简报预览  -->
		<previewChart
			ref="previewChart"
			v-if="preview"
			@associateData="pagechange"
			modifyDataUrl="web2/data/mddf/mdta"
			:modifyDataShow="modifyDataShow"
			:briefingShow="briefingShow"
			@modifyData="modifyData"
			@briefingClick="showBriefReportLayer"
		/>

		<LayerContainer>
			<LayerItem :layer-param="briefReportLayer" :layer-btn-click="layerBtnClick"><briefReportLayer ref="briefReportLayer" /></LayerItem>
		</LayerContainer>

		<!-- 报表设置弹窗 -->
		<setupReport ref="setupReport" view="web2/data/odnr/viewrp" saveUrl="web2/data/dmf/save" :authorityList="authorityList"></setupReport>
	</div>
</template>

<script>
import HistoryExp from './historyExp/historyExp.vue';
import HistorySta from './historySta/historySta.vue';
import sampling from './samplingData/sampling.vue';
import statement from '../../word/statement/statement.vue';
import briefReport from '../../word/briefReport/briefReport.vue';
import previewChart from '../../../components/previewChart.vue';
import setupReport from '../../../components/setupReport.vue';
import briefReportLayer from '../../../components/briefReportLayer.vue';

export default {
	name: 'history',
	components: {
		HistoryExp,
		HistorySta,
		sampling,
		statement,
		briefReport,
		previewChart,
		setupReport,
		briefReportLayer
	},
	data() {
		return {
			activeIndex: '2',
			queryBool: false, // 抽样数据是否携带查询条件
			samplingType: '2', //抽样数据页面数据类型
			samplingTime: {
				timeS: null,
				timeE: null
			}, // 抽样数据时间选择
			checkedDevice: [],
			checkedList: [], //测点数据搜索多选框列表数据
			checkedPoint: null, //测点数据搜索选框选中数据的pid
			form: {
				timeS: null,
				timeE: null
			}, //测点数据查询数据
			queryFile: 2, // 关联数据选择的查询文件条件
			selectStation: 2, //关联数据选择的查询测点条件
			queryFileList: [
				{
					id: 2,
					name: '全部数据'
				},
				{
					id: 1,
					name: '有数据'
				},
				{
					id: 0,
					name: '无数据'
				}
			], // 关联数据选择的查询文件条件选择列表
			stationType: [
				{
					id: 2,
					name: '全部测点'
				},
				{
					id: 1,
					name: '启用测点'
				},
				{
					id: 0,
					name: '停用测点'
				}
			], //关联数据查询测点条件选择列表
			showRelevance: false, // 关联测点菜单是否显示

			briefReportLayer: {
				title: '生成简报',
				itemClass: 'briefReport',
				confirmBtn: '确定',
				cancelBtn: '取消',
				confirmType: 'briefReportCon',
				cancelType: 'briefReportCan',
				hintText: '',
				hintColor: '#f00'
			}, //生成简报弹窗配置
			preview: false, // 简报预览是否加载
			modifyDataShow: false, //简报预览是否显示修改数据按钮
			fileBaseData: {}, //简报数据
			query: {}, //导航参数
			authorityList: [], //权限数组
			that: null
		};
	},
	created() {
		this.that = this;
		this.query = this.$route.query;
		this.getPointList();
		this.getPermissions();
	},
	mounted() {
		const D = new Date();
		let before = new Date(D.getTime() - 24 * 60 * 60 * 1000 * 7).format('yyyy-MM-dd') + ' 00:00:00';
		this.form.timeS = before;
		let now = new Date(D.getTime()).format('yyyy-MM-dd') + ' 23:59:59';
		this.form.timeE = now;
		// this.$refs.HistorySta.form = this.form;
		this.samplingTime.timeS = new Date(D.getTime() - 24 * 60 * 60 * 1000).format('yyyy-MM-dd hh');
		this.samplingTime.timeE = new Date(D.getTime()).format('yyyy-MM-dd hh');
		// this.$refs.HistorySta.item.time = true;
		if (this.query.hasOwnProperty('id')) {
			if (this.query.hasOwnProperty('pid')) {
				this.pagechange(this.query);
				this.$refs.HistorySta.buttonCallback('search');
			} else if (this.query.hasOwnProperty('pointId')) {
				this.checkedPoint = Number(this.query.pointId);
				this.$refs.HistorySta.checkedPoint = this.checkedPoint;
				setTimeout(() => {
					this.$refs.HistorySta.buttonCallback('search');
				}, 100);
			} else {
				this.$refs.HistorySta.buttonCallback('search');
			}
		} else {
			this.toRouter('index');
		}
	},
	methods: {
		// 获取页面权限
		getPermissions() {
			this.authorityList = [];
			this.axios.get('web2/data/odnr/giyu').then(res => {
				if (res.status) {
					this.authorityList = res.data;
				}
				this.briefingShow = this.judgment('authorityList', 310, this);
			});
		},
		// 获取测点列表
		getPointList() {
			var data = {
				id: this.query.id
			};
			this.axios.post('web2/data/odnr/gpname', data).then(res => {
				this.checkedList = [];
				if (res.status) {
					this.checkedList = res.data;
				}
			});
		},
		// 菜单切换
		handleSelect(key, keyPath) {
			// // console.log(key, keyPath);
			this.activeIndex = keyPath[0];
			if (key == 1) {
				if (this.query.hasOwnProperty('pid')) {
					this.showRelevance = false;
					this.$refs.HistoryExp.checkedArr = [];
					this.$refs.HistoryExp.checkedDevice = [];
					this.$refs.HistoryExp.checkedPointId = [];
					this.$refs.HistoryExp.isIndeterminate = false;
					this.$refs.HistoryExp.checkAll = false;
					this.$refs.HistoryExp.item.total = 0;
					this.$refs.HistoryExp.item.rows = [];
				}
				setTimeout(() => {
					let pageData = this.$refs.sampling.list;
					if (JSON.stringify(pageData) == '{}') {
						this.samplingQuery();
					}
				}, 300);
			} else if (key == 2) {
				if (this.query.hasOwnProperty('pid')) {
					// this.showRelevance = false;
					this.$refs.HistoryExp.checkedArr = [];
					this.$refs.HistoryExp.checkedDevice = [];
					this.$refs.HistoryExp.checkedPointId = [];
					this.$refs.HistoryExp.isIndeterminate = false;
					this.$refs.HistoryExp.checkAll = false;
					this.$refs.HistoryExp.item.total = 0;
					// this.$refs.HistoryExp.item.rows = [];
				}
			} else if (key == 4) {
				// const D = new Date();
				// let before = new Date(D.getTime() - 24 * 60 * 60 * 1000 * 7).format('yyyy-MM-dd') + ' 00:00:00';
				// this.samplingTime.timeS = before;
				// let now = new Date(D.getTime()).format('yyyy-MM-dd') + ' 23:59:59';
				// this.samplingTime.timeE = now;
				// this.checkedPoint = null;
				// setTimeout(() => {
				// 	this.$refs.sampling.getDeviceFile();
				// }, 300);
			}
		},
		// 进入关联数据
		pagechange(item) {
			// // console.log('测点');
			// // console.log(item);
			this.activeIndex = '3';
			this.showRelevance = true;
			if (this.activeIndex == '3') {
				this.$refs.HistoryExp.relevanceDate(item);
			}
		},
		// 抽样数据类型选择
		samplingTypeChange() {
			var data = [];
			this.queryBool = true;
			this.checkedList.forEach(i => {
				data.push({
					id: i.id,
					name: i.name
				});
			});
			if (this.checkedList.length == 0) {
				this.$message({
					showClose: true,
					message: '当前项目暂无测点！',
					type: 'warning'
				});
				return;
			}
			this.$refs.sampling.switchPageData(data);
		},
		// 抽样数据查询
		samplingQuery() {
			var data = [];
			this.queryBool = true;
			this.checkedList.forEach(i => {
				data.push({
					id: i.id,
					name: i.name
				});
			});
			if (this.checkedList.length == 0) {
				this.$message({
					showClose: true,
					message: '当前项目暂无测点！',
					type: 'warning'
				});
				return;
			}
			this.$refs.sampling.getChartData(data);
		},
		// 抽样数据顶部生成简报按钮点击
		generateBriefing(val) {
			this.$refs.sampling.generateBriefing(val);
		},
		// 抽样数据Excel下载
		downloadExcel(val) {
			// let times = this.$refs.sampling.time;
			// if (times.length == 0) {
			// 	this.$message({
			// 		showClose: true,
			// 		message: '请先选择时间！',
			// 		type: 'warning'
			// 	});
			// 	return;
			// }

			// let begin = new Date(times[0] + ':00:00').getTime();
			// let end = new Date(times[1] + ':00:00').getTime();
			// if (this.checkedList.length == 0) {
			// 	this.$message({
			// 		showClose: true,
			// 		message: '当前项目暂无测点！',
			// 		type: 'warning'
			// 	});
			// 	return;
			// } else if (begin > end) {
			// 	this.$message({
			// 		showClose: true,
			// 		message: '开始时间不能大于结束时间',
			// 		type: 'warning'
			// 	});
			// 	return;
			// }
			// let time = {
			// 	begin: times.timeS,
			// 	end: times.timeE
			// };
			this.$refs.sampling.downloadExcel(val);
		},
		// 测点数据查询按钮
		theQuery() {
			if (this.activeIndex == '2') {
				this.$refs.HistorySta.checkedPoint = this.checkedPoint;
				this.$refs.HistorySta.form = this.form;
				this.$refs.HistorySta.item.time = true;
				setTimeout(() => {
					this.$refs.HistorySta.buttonCallback('search');
				}, 300);
			} else if (this.activeIndex == '4') {
			}
		},
		//测点数据多选框选择
		changeValue(val) {
			this.$refs.HistorySta.checkedPoint = this.checkedPoint;
			setTimeout(() => {
				this.$refs.HistorySta.buttonCallback('search');
			}, 300);
		},
		//关联数据选择筛选类型
		selectData() {
			// this.claerCheckbox();
			// this.sortData();
			this.$refs.HistoryExp.queryFile = this.queryFile;
			this.$refs.HistoryExp.selectStation = this.selectStation;
			this.$refs.HistoryExp.claerCheckbox();
		},
		//弹出框按钮点击事件
		layerBtnClick(type) {
			if (type === 'briefReportCon') {
				$('.layerContainerBox').fadeOut(300);
				let form = this.$refs.briefReportLayer.form;
				this.$refs.briefReport.showLayer(form);
			} else if (type === 'preview') {
				$('.layerContainerBox').fadeOut(300, () => {
					this.batchParseFile(this.checkedDevice);
				});
			} else if (type === 'background') {
				this.saveWord();
			}
		},
		//按钮组件点击事件回调
		buttonCallback(data, val) {
			// // console.log(data);
			let arr = data.filesId.split(',');
			if (arr.length > 300) {
				this.$message({
					showClose: true,
					message: '此操作勾选数据不能超过300条！',
					type: 'warning'
				});
				return;
			}
			if (val == 'save') {
				this.$refs.setupReport.saveFlie(data);
			} else if (val == 'preview') {
				this.$refs.setupReport.preview(data);
			}
		},
		//解析单个文件 => 预览
		filePreview(item) {
			this.modifyDataShow = this.judgment('authorityList', 355, this);
			this.axios.post(`web2/data/odnr/gpfdata`, { fileId: item.id }).then(res => {
				if (res.status) {
					this.preview = true;
					this.fileBaseData = res.data;
					let fileType = this.fileBaseData.fileName.split('.')[1];
					// this.fileBaseData.deviceparam = this.$refs.fileLayer.item.deviceParamInfo;
					for (let i = 0; i < res.data.xdata.length; i++) {
						res.data.xdata[i] = Number(Number(res.data.xdata[i]).toFixed(4));
					}
					for (let i = 0; i < res.data.ydata.length; i++) {
						res.data.ydata[i] = Number(Number(res.data.ydata[i]).toFixed(4));
					}
					for (let i = 0; i < res.data.zdata.length; i++) {
						res.data.zdata[i] = Number(Number(res.data.zdata[i]).toFixed(4));
					}
					for (let i = 0; i < res.data.dataTime.length; i++) {
						if (fileType === 'bin') {
							res.data.dataTime[i] = Number(res.data.dataTime[i]);
						} else {
							res.data.dataTime[i] = Number(res.data.dataTime[i]);
						}
					}

					let arr = [res.data.xmax, res.data.ymax, res.data.zmax];
					// // console.log(fileType);
					let data = {
						sampleRate: res.data.sampleRate,
						fileType: fileType,
						date: res.data.dataTime,
						series: [res.data.xdata, res.data.ydata, res.data.zdata],
						maxData: arr.max(),
						units: res.data.paramUnit,
						maxXData: res.data.xmax,
						maxYData: res.data.ymax,
						maxZData: res.data.zmax,
						mainFX: res.data.xfrequency,
						mainFY: res.data.yfrequency,
						mainFZ: res.data.zfrequency,
						maxXTime: res.data.xmaxTime,
						maxYTime: res.data.ymaxTime,
						maxZTime: res.data.zmaxTime,
						fileName: res.data.fileName,
						fileTimeStamp: res.data.fileDate,
						pointName: res.data.pointName,
						deviceParam: res.data.deviceParam,
						companyName: res.data.companyName,
						address: res.data.address,
						deviceNum: res.data.deviceNum,
						projectName: res.data.projectName,
						negativeDelay: res.data.negativeDelay,
						// pageType: 1,//是否显示关联按钮
						fileTime: item.fileTime, //跳转关联时所需数据
						pid: item.pid, //跳转关联时所需数据
						dataId: item.id //修改数据时需要的id
					};
					//是否显示关联按钮
					if (item.hasOwnProperty('pageType')) {
						data.pageType = item.pageType;
					}
					this.fileBaseData = data;
					setTimeout(() => {
						this.$refs.previewChart.drawChart(data);
					}, 100);
				} else {
				}
			});
		},
		//解析单个文件 => 抽样数据预览
		samplingFilePreview(item, res = {}) {
			if (JSON.stringify(res) == '{}') {
				this.$message({
					showClose: true,
					message: '暂无数据!',
					type: 'warning'
				});
				return;
			}
			this.modifyDataShow = false;
			if (res.status) {
				this.preview = true;
				this.fileBaseData = res.data;
				let fileType = this.fileBaseData.fileName.split('.')[1];
				// this.fileBaseData.deviceparam = this.$refs.fileLayer.item.deviceParamInfo;
				for (let i = 0; i < res.data.xdata.length; i++) {
					res.data.xdata[i] = Number(Number(res.data.xdata[i]).toFixed(4));
				}
				for (let i = 0; i < res.data.ydata.length; i++) {
					res.data.ydata[i] = Number(Number(res.data.ydata[i]).toFixed(4));
				}
				for (let i = 0; i < res.data.zdata.length; i++) {
					res.data.zdata[i] = Number(Number(res.data.zdata[i]).toFixed(4));
				}
				for (let i = 0; i < res.data.dataTime.length; i++) {
					if (fileType === 'bin') {
						res.data.dataTime[i] = Number(res.data.dataTime[i]);
					} else {
						res.data.dataTime[i] = Number(res.data.dataTime[i]);
					}
				}

				let arr = [res.data.xmax, res.data.ymax, res.data.zmax];
				// // console.log(fileType);
				let data = {
					sampleRate: res.data.sampleRate,
					fileType: fileType,
					date: res.data.dataTime,
					series: [res.data.xdata, res.data.ydata, res.data.zdata],
					maxData: arr.max(),
					units: res.data.paramUnit,
					maxXData: res.data.xmax,
					maxYData: res.data.ymax,
					maxZData: res.data.zmax,
					mainFX: res.data.xfrequency,
					mainFY: res.data.yfrequency,
					mainFZ: res.data.zfrequency,
					maxXTime: res.data.xmaxTime,
					maxYTime: res.data.ymaxTime,
					maxZTime: res.data.zmaxTime,
					fileName: res.data.fileName,
					fileTimeStamp: res.data.fileDate,
					pointName: res.data.pointName,
					deviceParam: res.data.deviceParam,
					companyName: res.data.companyName,
					address: res.data.address,
					deviceNum: res.data.deviceNum,
					projectName: res.data.projectName,
					negativeDelay: res.data.negativeDelay,
					pageType: 1, //是否显示关联按钮
					fileTime: res.data.fileTime, //跳转关联时所需数据
					pid: item.id //跳转关联时所需数据
				};
				this.fileBaseData = data;
				setTimeout(() => {
					this.$refs.previewChart.drawChart(this.fileBaseData);
				}, 100);
			} else {
			}
		},
		// 预览曲线图弹窗修改数据按钮点击后执行数据
		modifyData() {
			this.preview = false;
			if (this.activeIndex == '2') {
				this.$refs.HistorySta.buttonCallback('search');
			}
		},
		//显示简报信息弹窗
		showBriefReportLayer() {
			this.preview = false;
			this.$refs.briefReportLayer.showLayer(this.fileBaseData);
			// {
			// 	fileName: '11111',
			// 	statrTime: '2022-10-10',
			// 	endTime: '2022-10-11',
			// 	threshold: 12,
			// 	pointNum: 6
			// }
			this.$refs.briefReport.setWordImages(this.fileBaseData);
		}
	},
	beforeRouteUpdate(to, from, next) {
		// // console.log('更新');
		// // console.log(to)
		this.query = to.query;
		if (this.query.hasOwnProperty('pid')) {
			this.pagechange(this.query);
		}
	}
};
</script>

<style scoped lang="scss">
.historyPage {
	width: 100%;
	height: 100%;
	overflow: hidden;

	.header {
		padding: 0 15px 0 15px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		::v-deep {
			.el-menu-demo .el-menu-item {
				font-size: 17px;
				font-weight: 600;
				color: #303133;
				letter-spacing: 1px;
			}

			.el-menu.el-menu--horizontal {
				border-bottom: none;
			}
		}

		.right-box {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			.time {
				> span {
					display: inline-block;
					margin: 0 5px;
				}
			}

			::v-deep {
				.el-select__tags-text {
					max-width: 70px;
				}
			}
		}

		.chart-screen {
			.selection {
				::v-deep {
					.el-select {
						width: 220px !important;

						.el-input {
							width: 100% !important;
						}
					}
				}
			}

			.popover {
				& + .popover {
					margin-left: 15px;
				}
			}
		}

		.screen {
			margin: 0 15px;
			display: flex;
			justify-content: flex-start;
			align-items: center;

			::v-deep {
				.el-input,
				.el-select {
					width: 180px;
					height: 32px;
				}

				.el-input__inner {
					padding-right: 0;
					height: 100% !important;
				}

				.el-input__icon {
					height: auto;
				}

				.el-input__suffix {
					top: -4px;
				}

				.el-input__prefix {
					top: -4px;
				}
			}

			span {
				font-size: 15px;
			}
		}

		.screen-cell {
			margin-left: 20px;
		}
	}

	.child-page {
		display: flex;
		width: 100%;
		height: calc(100% - 60px);
		// justify-content: ;
	}
}
</style>
<style lang="scss">
.chart-time {
	.el-time-spinner {
		display: flex;
		justify-content: center;
	}

	.el-time-spinner .el-time-spinner__wrapper:nth-child(2) {
		display: none;
	}
}

.chart-briefing-but {
	width: 110px;
	min-width: auto;

	.select-but {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.el-button + .el-button {
			margin-left: 0;
			margin-top: 15px;
		}
	}
}
</style>
